"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const vue_property_decorator_1 = require("vue-property-decorator");
let UiFileInput = class UiFileInput extends vue_property_decorator_1.Vue {
    get iconClass() {
        if (this.icon === null) {
            return {};
        }
        let val = {};
        val[this.icon] = true;
        return val;
    }
    emitFile(event) {
        const target = event.target;
        this.$emit('input', target.files);
    }
};
__decorate([
    vue_property_decorator_1.Prop()
], UiFileInput.prototype, "name", void 0);
__decorate([
    vue_property_decorator_1.Prop({ default: false })
], UiFileInput.prototype, "required", void 0);
__decorate([
    vue_property_decorator_1.Prop()
], UiFileInput.prototype, "hint", void 0);
__decorate([
    vue_property_decorator_1.Prop()
], UiFileInput.prototype, "label", void 0);
__decorate([
    vue_property_decorator_1.Prop({ default: false })
], UiFileInput.prototype, "disabled", void 0);
__decorate([
    vue_property_decorator_1.Prop({ default: null })
], UiFileInput.prototype, "icon", void 0);
__decorate([
    vue_property_decorator_1.Prop()
], UiFileInput.prototype, "placeholder", void 0);
UiFileInput = __decorate([
    vue_property_decorator_1.Component
], UiFileInput);
exports.default = UiFileInput;

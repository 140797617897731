<template>
    <transition name="modal">
        <div class="modal fade in" id="emptyModal" tabindex="-1" @click.stop="backDropClick" role="dialog" aria-labelledby="emptyModalTitle"
             style="display: block;">
            <div class="modal-dialog modal-sm" @click.stop="" role="document" :style="{width: width, margin: margin}">
                <div class="fade" id="emptyModalEventHandler"></div>
                <div class="modal-content">
                    <div class="modal-header">
                        <button
                                @click="close"
                                type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                                aria-hidden="true">×</span>
                        </button>
                        <slot name="header"></slot>

                    </div>
                    
                    <div class="modal-body">
                        <ui-loading :is-loading=loading>
                            <slot name="body">

                            </slot>
                        </ui-loading>
                    </div>
                    <div class="modal-footer" >
                        <slot name="footer"></slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "modal",
        props: {
            backDrop: {
                default: true
            },
            width: {
                default: '80%'
            },
           margin: {
             default: ''
           },
            loading: {
                default: false
            }
        },
        methods: {
            backDropClick() {
                if (this.backDrop) {
                    return this.$emit('close')
                }
            },
            close() {
                this.$emit('close')
            }
        }
    }
</script>

<style scoped>
    .modal {
        background-color: rgba(0, 0, 0, .5);
    }

    .modal-enter {
        opacity: 0;
    }

    .modal-body {
      padding: 15px 20px 15px 20px;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    /*
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        margin: 0px auto;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all .3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        margin: 20px 0;
    }

    .modal-default-button {
        float: right;
    }

    .modal-mask .close {
        position: relative;
        top: -40px;
    }

    */
</style>
<template>
    <div class="list-group-item col-md-12">
        <div class="col-md-5">
            <span>{{name}}</span>
        </div>

        <div class="col-md-2">
            <strong>Status:</strong> {{installStatus}}
        </div>

        <div class="col-md-2">
            <span v-if="clientProduct.created_at">{{clientProduct.created_at | date}}</span>
        </div>

        <div class="pull-right">
            <span v-if="!isInstalled" @click="install(clientProduct)" class="btn blue">
                <i data-v-575ec4e4="" class="fa fa-cogs"></i>
                Instalar
            </span>

            <a :disabled="installValue > 0" target="_blank" class="btn blue" :href="logLink"><i class="fa fa-download"></i> Baixar Log</a>

            <span class=" btn btn-danger" @click="$emit('remove', clientProduct)">
                <i class="fa fa-trash"></i>
                Remover
            </span>
        </div>
  </div>
</template>

<script>
import mixin from './mixin';

export default {
  name: "emails_plus",

  mixins: [
    mixin
  ],

  computed: {
    name() {
      return `${this.getConfig('emails_plus').value} ${this.clientProduct.name}`
    },

    logLink() {
      return this.clientProduct.log_url
    },

    installValue() {
      return this.getConfig('installed', {value: 0}).value
    }
  },
}
</script>

<style scoped>

</style>
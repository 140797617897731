<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <ui-select
          :options="options.payment_status"
          v-model="ui.filters.payment_status"
          label="Status das cobranças"
        ></ui-select>
      </div>
      <div class="col-md-1 vertical-checkbox">
        <ui-check v-model="ui.filters.in_arrears" label="Em atraso"></ui-check>
      </div>

      <div class="col-md-2 vertical-checkbox">
        <button class="btn blue" @click="search">Filtrar</button>
      </div>
    </div>
    <table ref="table" class="table">
      <thead>
        <tr>
          <th>Valor Pago</th>
          <th>Valor</th>
          <th>Data de Vencimento</th>
          <th>Data do Pagamento</th>
          <th>Status</th>
          <th>2º Via</th>
          <th width="150px">Enviar por E-Mail</th>
        </tr>
      </thead>
      <tbody v-if="ui.items.length">
        <tr
          v-for="item in ui.items"
          :class="{
                strikeout: item.payment_status === 2
            }"
        >
          <td>
            <span v-if="item.payed">{{item.payed | money}}</span>
            <span v-else>-</span>
          </td>
          <td>{{item.value | money}}</td>
          <td>{{item.due_to | date}}</td>
          <td v-if="item.payment_date ">
            {{item.payment_date | date}}
            <i
              v-if="item.days_past <= 0 && item.days_past !== null"
              class="fa fa-check text-success"
            >Dentro do prazo</i>
            <i
              v-if="item.days_past > 0 && item.days_past !== null"
              class="fa fa-check text-danger"
            >Atraso</i>
          </td>
          <td v-else>-</td>
          <td>
            {{item.payment_status_info.name}}
            <i
              class="fa"
              :class="{
                            'fa-clock-o text-warning': item.payment_status === 0,
                            'fa-check text-success': item.payment_status === 1,
                            'text-danger': item.in_arrears
                        }"
            ></i>
            <label v-if="item.in_arrears" class="label label-danger">Em atraso</label>
          </td>
          <td>
            <a :href="item.link_2via" v-if="item.payment_status === 0" target="_blank">2º Via</a>
            <a v-else style="text-decoration: line-through">2º Via</a>
          </td>
          <td class="text-center">
            <a href>
              <i @click.prevent="sendEmail(item.id)" class="fa fa-envelope"></i>
            </a>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="4" class="text-center">
            <h2>Nenhum resultado encontrado!</h2>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "installments",

  props: {
    client: {
      default: () => {},
      required: true
    },

    options: {
      default: () => {},
      required: true
    }
  },

  data() {
    return {
      ui: {
        statuses: {
          loading: false
        },
        items: [],
        filters: {
          payment_status: null,
          in_arrears: null
        }
      }
    };
  },

  mounted() {
    this.search();
  },

  methods: {
    search() {
      let query = {};
      if (this.ui.filters.payment_status) {
        query.payment_status = this.ui.filters.payment_status ;
      }

      if ( this.ui.filters.in_arrears) {
        query.in_arrears = 1 ;
      }

      this.request()
        .block(this.$refs.table)
        .get(
          "Carregando cobranças",
          `/clients/${this.client.id}/installments`,
          {
            query: query,


          }
        )
        .then(response => {
          this.ui.items = response.data;
        });
    },

    sendEmail(id) {
      this.request()
        .block(this.$refs.table)
        .post(
          "Enviando e-mail de cobrança",
          `/clients/${this.client.id}/send-installment-by-email/${id}`
        );
    }
  }
};
</script>

<style scoped>
td {
  position: relative;
}

tr.strikeout td:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 0;
  border-bottom: 1px solid red;
  width: 100%;
}
</style>
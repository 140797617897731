<template>
  <div>
    <div :class="envClass">
      <div v-if="LOGGED">
        <navigation></navigation>

        <div class="container-fluid">
          <ul class="page-breadcrumb breadcrumb"></ul>
          <router-view></router-view>
        </div>

        <div class="col-md-12" style="position: fixed; bottom: 15px; right: 15px;">
          <button
            v-for="message in LOADING_MESSAGES"
            class="btn btn-primary pull-right"
            style="margin-left: 5px"
          >{{message.message}}...</button>
        </div>
      </div>

      <div v-else>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import Navigation from "@components/Navigation.vue";

export default {
  name: "app",
  components: { navigation: Navigation },

  computed: {
    ...mapGetters(["LOADING_MESSAGES", "LOGGED", "EXPIRATION", "REMEMBER_ME"]),

    envClass() {
      let l = {};

      l[process.env.env] = true;

      return l;
    }
  },

  methods: {
    ...mapActions(["ADD_LOADING", "SET_USER"])
  },

  mounted() {
    if (!this.LOGGED) {
      document.getElementsByTagName("body")[0].className = "login";
      return;
    }

    document.getElementsByTagName("body")[0].className =
      "page-header-fixed page-container-bg-solid page-sidebar-closed-hide-logo page-header-fixed-mobile page-footer-fixed1";

    setInterval(() => {
      let diff = moment(this.EXPIRATION).diff(moment(), "minutes");
      if (diff <= 10) {
        this.request()
          .post("Recuperando dados do usuário", `users/token`, {
            token: this.REMEMBER_ME
          })
          .then(response => {
            this.SET_USER(response.data);
          });
      }
    }, 60 * 1000);
  }
};
</script>
module.exports = {
    'name': 'nome',
    'code': 'código',
    'status': 'status',
    'billing_day': 'dia do pagamento',
    'obs': 'observação',
    'created_at': 'criado em',
    'updated_at': 'atualizado em',
    'delted_at': 'deletado em',
    'user_id': 'id do usuário',
    'customer_id': 'id do customer',
    'vendor_id': 'id do vendedor',
    'sl_id': 'id da super lógica',
    'contact_type': 'tipo de contato',
    'freezed_at': 'congelado em',
    'email': 'email',
    'phone1': 'telefone 1',
    'phone2': 'telefone 2',
    'billing_landmark': 'endereço de cobrança',
    'disable_monthly': 'desativar mensalidade',
    'rg': 'RG',
    'company_name': 'Razão social',
    'same_address': 'mesmo endereço para cobrança',
    'billing_cep': 'cep para cobrança',
    'billing_number': 'número para cobrança',
    'billing_complement': 'complemento para cobrança',
    'billing_neighborhood': 'bairro para cobrança',
    'billing_city': 'cidade para cobrança',
    'billing_uf': 'estado para cobrança',
    'state_registration': 'inscrição estadual',
    'cpf_cnpj': 'C.P.F/C.N.P.J',
    'cep': 'C.E.P',
    'address': 'endereço',
    'number': 'número',
    'complement': 'complemento',
    'neighborhood': 'bairro',
    'city': 'cidade',
    'uf': 'estado',
    'creci': 'C.R.E.C.I',
    'creci_uf': 'estado do C.R.E.C.I',
    'mysquite_id': 'id do mysuite',
    'suspension_date': 'data de suspenção',
    'billet_login': 'a.k.a codigo',
    'microsistec_mail': 'microsistec mail',
    'boarding_info': 'informação de embarque',
    'recurrency': 'valor da recorrencia',
    'cancel_date': 'data de cancelamento',
    'due_to': 'proximo vencimento',
    'second_invoice_link': 'link da 2º via do boleto',
    'contact_emails': 'emails de contato',
    'ignore_block': 'ignorar bloqueio',
    'migrated': 'migrado',
    'freshdesk_id': 'id do freshdesk'
};

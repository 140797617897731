"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const vue_property_decorator_1 = require("vue-property-decorator");
let ChangesUi = class ChangesUi extends vue_property_decorator_1.Vue {
    constructor() {
        super(...arguments);
        this.changes = [
            {
                id: 1,
                date: "09/12/2019",
                name: "Atualização Triggers",
                description: `As triggers foram ajutadas para dar vazão as necessidades do adiministrativo.`,
                changes: [
                    "Possibilidade de tornar uma trigger ativa ou inativa",
                    "Enviar e-mail com tags html dentro das mesmas",
                    "Remoção do link do Freshdesk do menu principal da Central",
                    "Adição de um log de mudanças"
                ]
            }
        ];
    }
};
ChangesUi = __decorate([
    vue_property_decorator_1.Component
], ChangesUi);
exports.default = ChangesUi;

<template>
  <ui-loading :is-loading="this.ui.loading">
    <div>
      <div id="adminDash" class="row">
        <div class="col-md-12">
          <small>Tabela atualizada em:
            <b>{{ ui.current.created_at | datetime(format = 'DD/MM/YYYY HH:mm') }}</b></small>
          <div class="title" style="position: relative;">
            <h3 style="display: inline-block;">Dados gerais (administrativo)</h3>
            <button class="btn btn-primary edit-panel-btn" @click="editMetrics">Alterar tabela</button>
          </div>

          <ui-metrics-modal
              :metrics="metrics"
              v-if="require_editMetrics === true"
              @close="require_editMetrics = false"
          ></ui-metrics-modal>

          <div class="admin-data">
            <div class="col-md-3">
            <span>Faturamento Liq. : <b>{{ ui.current.liquidRevenue | money }}</b>
            <small :class="{
              'text-danger': ui.previous.liquidRevenue > ui.current.liquidRevenue,
              'text-success': ui.previous.liquidRevenue < ui.current.liquidRevenue,
              'text-info': ui.previous.liquidRevenue === ui.current.liquidRevenue,
            }" :title="'Valor do último dia do mês passado: '+ ui.previous.liquidRevenue"><i :class="{
               'fa fa-arrow-down': ui.previous.liquidRevenue > ui.current.liquidRevenue,
               'fa fa-arrow-up': ui.previous.liquidRevenue < ui.current.liquidRevenue,
               'fa fa-arrow-right': ui.previous.liquidRevenue === ui.current.liquidRevenue,
            }"></i> {{ ui.previous.liquidRevenue | money }}</small>
            </span>
            </div>
            <div class="col-md-3">
            <span>Faturamento Emit. : <b>{{ ui.current.issuedRevenue | money }}</b>
            <small :class="{
              'text-danger': ui.previous.issuedRevenue > ui.current.issuedRevenue,
              'text-success': ui.previous.issuedRevenue < ui.current.issuedRevenue,
              'text-info': ui.previous.issuedRevenue === ui.current.issuedRevenue,
            }" :title="'Valor do último dia do mês passado: '+ ui.previous.issuedRevenue"><i :class="{
               'fa fa-arrow-down': ui.previous.issuedRevenue > ui.current.issuedRevenue,
               'fa fa-arrow-up': ui.previous.issuedRevenue < ui.current.issuedRevenue,
               'fa fa-arrow-right': ui.previous.issuedRevenue === ui.current.issuedRevenue,
            }"></i> {{ ui.previous.issuedRevenue | money }}</small>
            </span>
            </div>
            <div class="col-md-3">
            <span>MMR: <b>{{ ui.current.mmr | money }}</b>
                          <small :class="{
              'text-danger': ui.previous.mmr > ui.current.mmr,
              'text-success': ui.previous.mmr < ui.current.mmr,
              'text-info': ui.previous.mmr === ui.current.mmr,
            }" :title="'Valor do último dia do mês passado: '+ ui.previous.mmr"><i :class="{
               'fa fa-arrow-down': ui.previous.mmr > ui.current.mmr,
               'fa fa-arrow-up': ui.previous.mmr < ui.current.mmr,
               'fa fa-arrow-right': ui.previous.mmr === ui.current.mmr,
            }"></i> {{ ui.previous.mmr | money }}</small>
            </span>
            </div>
            <div class="col-md-3">
            <span>Total de Clientes: <b>{{ ui.current.totalClients }}</b>
                        <small :class="{
              'text-danger': ui.previous.totalClients > ui.current.totalClients,
              'text-success': ui.previous.totalClients < ui.current.totalClients,
              'text-info': ui.previous.totalClients === ui.current.totalClients,
            }" :title="'Valor do último dia do mês passado: '+ ui.previous.totalClients"><i :class="{
               'fa fa-arrow-down': ui.previous.totalClients > ui.current.totalClients,
               'fa fa-arrow-up': ui.previous.totalClients < ui.current.totalClients,
               'fa fa-arrow-right': ui.previous.totalClients === ui.current.totalClients,
            }"></i> {{ ui.previous.totalClients }}</small>
            </span>
            </div>
            <div class="col-md-3">
            <span>Ticket Médio: <b>{{ ui.current.ticket | money }}</b>
                                    <small :class="{
              'text-danger': ui.previous.ticket > ui.current.ticket,
              'text-success': ui.previous.ticket < ui.current.ticket,
              'text-info': ui.previous.ticket === ui.current.ticket,
            }" :title="'Valor do último dia do mês passado: '+ ui.previous.ticket"><i :class="{
               'fa fa-arrow-down': ui.previous.ticket > ui.current.ticket,
               'fa fa-arrow-up': ui.previous.ticket < ui.current.ticket,
               'fa fa-arrow-right': ui.previous.ticket === ui.current.ticket,
            }"></i> {{ ui.previous.ticket | money }}</small>
            </span>
            </div>
            <div class="col-md-3">
            <span>Churning: <b>{{ ui.current.churningCumulative }}%</b>
            <small :class="{
              'text-success': ui.previous.churningCumulative > ui.current.churningCumulative,
              'text-danger': ui.previous.churningCumulative < ui.current.churningCumulative,
              'text-info': ui.previous.churningCumulative === ui.current.churningCumulative,
            }" :title="'Valor do último dia do mês passado: '+ ui.previous.churningCumulative"><i :class="{
               'fa fa-arrow-down': ui.previous.churningCumulative > ui.current.churningCumulative,
               'fa fa-arrow-up': ui.previous.churningCumulative < ui.current.churningCumulative,
               'fa fa-arrow-right': ui.previous.churningCumulative === ui.current.churningCumulative,
            }"></i> {{ ui.previous.churningCumulative }}%</small>
            </span>
            </div>
            <div class="col-md-3">
            <span>Growth: <b>{{ ui.current.growthCumulative }}%</b>
            <small :class="{
              'text-danger': ui.previous.growthCumulative > ui.current.growthCumulative,
              'text-success': ui.previous.growthCumulative < ui.current.growthCumulative,
              'text-info': ui.previous.growthCumulative === ui.current.growthCumulative,
            }" :title="'Valor do último dia do mês passado: '+ ui.previous.growthCumulative"><i :class="{
               'fa fa-arrow-down': ui.previous.growthCumulative > ui.current.growthCumulative,
               'fa fa-arrow-up': ui.previous.growthCumulative < ui.current.growthCumulative,
               'fa fa-arrow-right': ui.previous.growthCumulative === ui.current.growthCumulative,
            }"></i> {{ ui.previous.growthCumulative }}%</small>
            </span>
            </div>
            <div class="col-md-3">
            <span>Inadimplência: <b>{{ ui.current.nonPayment }}%</b>
            <small :class="{
              'text-success': ui.previous.nonPayment > ui.current.nonPayment,
              'text-danger': ui.previous.nonPayment < ui.current.nonPayment,
              'text-info': ui.previous.nonPayment === ui.current.nonPayment,
            }" :title="'Valor do último dia do mês passado: '+ ui.previous.nonPayment"><i :class="{
               'fa fa-arrow-down': ui.previous.nonPayment > ui.current.nonPayment,
               'fa fa-arrow-up': ui.previous.nonPayment < ui.current.nonPayment,
               'fa fa-arrow-right': ui.previous.nonPayment === ui.current.nonPayment,
            }"></i> {{ ui.previous.nonPayment }}%</small>
            </span>
            </div>
            <div class="col-md-3">
            <span>LT: <b>{{ ui.current.lt }} meses</b>
            <small :class="{
              'text-danger': ui.previous.lt > ui.current.lt,
              'text-success': ui.previous.lt < ui.current.lt,
              'text-info': ui.previous.lt === ui.current.lt,
            }" :title="'Valor do último dia do mês passado: '+ ui.previous.lt"><i :class="{
               'fa fa-arrow-down': ui.previous.lt > ui.current.lt,
               'fa fa-arrow-up': ui.previous.lt < ui.current.lt,
               'fa fa-arrow-right': ui.previous.lt === ui.current.lt,
            }"></i> {{ ui.previous.lt }} meses</small>
            </span>
            </div>
            <div class="col-md-3">
            <span>LTV: <b>{{ ui.current.ltv | money }}</b>
                        <small :class="{
              'text-danger': ui.previous.ltv > ui.current.ltv,
              'text-success': ui.previous.ltv < ui.current.ltv,
              'text-info': ui.previous.ltv === ui.current.ltv,
            }" :title="'Valor do último dia do mês passado: '+ ui.previous.ltv"><i :class="{
               'fa fa-arrow-down': ui.previous.ltv > ui.current.ltv,
               'fa fa-arrow-up': ui.previous.ltv < ui.current.ltv,
               'fa fa-arrow-right': ui.previous.ltv === ui.current.ltv,
            }"></i> {{ ui.previous.ltv | money }}</small>
            </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row tables_div">
        <div class="col-md-12">
          <div class="title">
            <h3>Dados do mês</h3>
          </div>
          <div class="col-md-3">
                        <span>Ativados:
                            <b>
                            <router-link :to="{name: 'clients.index', query: {status_classification: 1}}">
                                    {{ ui.current.activatedCumulative }}
                                </router-link>
                            </b>
                        <small :class="{
              'text-danger': ui.previous.activatedCumulative > ui.current.activatedCumulative,
              'text-success': ui.previous.activatedCumulative < ui.current.activatedCumulative,
              'text-info': ui.previous.activatedCumulative === ui.current.activatedCumulative,
            }" :title="'Valor do último dia do mês passado: '+ ui.previous.activatedCumulative"><i :class="{
               'fa fa-arrow-down': ui.previous.activatedCumulative > ui.current.activatedCumulative,
               'fa fa-arrow-up': ui.previous.activatedCumulative < ui.current.activatedCumulative,
               'fa fa-arrow-right': ui.previous.activatedCumulative === ui.current.activatedCumulative,
            }"></i> {{ ui.previous.activatedCumulative }}</small>
                        </span> <small class="small_mmr">MMR:
            {{ ui.current.activatedRecurrencyCumulative | money }}</small>
          </div>
          <div class="col-md-3">
                        <span>Resgatados:
                            <b>
                                <router-link :to="{name: 'clients.index', query: {status_classification: 2}}">
                                    {{ ui.current.rescuedCumulative }}
                                </router-link>
                        <small :class="{
              'text-danger': ui.previous.rescuedCumulative > ui.current.rescuedCumulative,
              'text-success': ui.previous.rescuedCumulative < ui.current.rescuedCumulative,
              'text-info': ui.previous.rescuedCumulative === ui.current.rescuedCumulative,
              }" :title="'Valor do último dia do mês passado: '+ ui.previous.rescuedCumulative"><i :class="{
               'fa fa-arrow-down': ui.previous.rescuedCumulative > ui.current.rescuedCumulative,
               'fa fa-arrow-up': ui.previous.rescuedCumulative < ui.current.rescuedCumulative,
               'fa fa-arrow-right': ui.previous.rescuedCumulative === ui.current.rescuedCumulative,
            }"></i> {{ ui.previous.rescuedCumulative }}</small>
                            </b>
                        </span> <small class="small_mmr">MMR:
            {{ ui.current.rescuedRecurrencyCumulative | money }}</small>
          </div>
          <div class="col-md-3">
                        <span>Cancelados:
                            <b>
                                <router-link :to="{name: 'clients.index', query: {status_classification: 3}}">
                                    {{ ui.current.canceledCumulative }}
                                </router-link>
                        <small :class="{
              'text-success': ui.previous.canceledCumulative > ui.current.canceledCumulative,
              'text-danger': ui.previous.canceledCumulative < ui.current.canceledCumulative,
              'text-info': ui.previous.canceledCumulative === ui.current.canceledCumulative,
            }" :title="'Valor do último dia do mês passado: '+ ui.previous.canceledCumulative"><i :class="{
               'fa fa-arrow-down': ui.previous.canceledCumulative > ui.current.canceledCumulative,
               'fa fa-arrow-up': ui.previous.canceledCumulative < ui.current.canceledCumulative,
               'fa fa-arrow-right': ui.previous.canceledCumulative === ui.current.canceledCumulative,
            }"></i> {{ ui.previous.canceledCumulative }}</small>
                            </b>
                        </span> <small class="small_mmr">MMR:
            {{ ui.current.canceledRecurrencyCumulative | money }}</small>
          </div>
          <div class="col-md-3">
                        <span>Atrasados:
                            <b>
                                <router-link :to="{name: 'clients.index', query: {status: 2}}">
                                    {{ ui.current.latePayers }}
                                </router-link>
                        <small :class="{
              'text-success': ui.previous.latePayers > ui.current.latePayers,
              'text-danger': ui.previous.latePayers < ui.current.latePayers,
              'text-info': ui.previous.latePayers === ui.current.latePayers,
            }" :title="'Valor do último dia do mês passado: '+ ui.previous.latePayers"><i :class="{
               'fa fa-arrow-down': ui.previous.latePayers > ui.current.latePayers,
               'fa fa-arrow-up': ui.previous.latePayers < ui.current.latePayers,
               'fa fa-arrow-right': ui.previous.latePayers === ui.current.latePayers,
            }"></i> {{ ui.previous.latePayers }}</small>
                            </b>
                        </span> <small class="small_mmr">MMR: {{ ui.current.latePayersRecurrency | money }}</small>
          </div>
        </div>
      </div>
      <div class="row tables_div">
        <div class="col-md-4">
          <div class="title">
            <h3>Ultimos suspensos <span v-if="clients.pastDue.length">- {{ clients.pastDue.length }}</span></h3>
          </div>
          <div>
            <table class="table" style="border-top: none">
              <thead>
              <tr>
                <th>Código</th>
                <th>Nome</th>
                <th>Atraso</th>
                <th>Data</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, key) in clients.pastDue" v-bind:key="key">
                <td>
                  <router-link :to="{name: 'clients.edit.index', params: {id: item.id}}">{{ item.code }}</router-link>
                </td>
                <td>{{ item.name }}</td>
                <td>{{ item.days_past_due }} dias</td>
                <td>{{ item.suspension_date | date }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-4">
          <div class="title">
            <h3>Próximos a serem cancelados <span
                v-if="clients.soonCanceled.length">- {{ clients.soonCanceled.length }}</span></h3>
          </div>
          <div>
            <table class="table" style="border-top: none">
              <thead>
              <tr>
                <th>Código</th>
                <th>Nome</th>
                <th>Cancelar em</th>
                <th>Previsão</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, key) in clients.soonCanceled" v-bind:key="key">
                <td>
                  <router-link :to="{name: 'clients.edit.index', params: {id: item.id}}">{{ item.code }}</router-link>
                </td>
                <td>{{ item.name }}</td>
                <td v-if="item.days_due_cancel != null">{{ 0 + item.days_due_cancel }} dias</td>
                <td v-else class="label-warning">CANCELAR!</td>
                <td v-if="item.due_cancel_date != null">{{ item.due_cancel_date | date }}</td>
                <td v-else class="label-warning"></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-4">
          <div class="title">
            <h3>Clientes sem recorrência <span
                v-if="clients.nullRecurrency.length">- {{ clients.nullRecurrency.length }}</span></h3>
          </div>
          <div>
            <table class="table" style="border-top: none">
              <thead>
              <tr>
                <th>Código</th>
                <th>Nome</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, key) in clients.nullRecurrency" v-bind:key="key">
                <td>
                  <router-link :to="{name: 'clients.edit.index', params: {id: item.id}}">{{ item.code }}</router-link>
                </td>
                <td>{{ item.name }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>


      </div>
    </div>
  </ui-loading>

</template>
<style scoped>
.title {
  margin-top: 5px;
  border-bottom: 1px solid #365F42;
  margin-bottom: 10px
}

.edit-panel-btn {
  position: absolute;
  right: 0;
  bottom: 10px;
}


small, i {
  font-size: 10px !important;
}

div.admin-data .col-md-3 {
  margin-bottom: 5px;
}

span {
  font-size: 14px;
}

table {
  border-top: none;
  overflow-y: scroll;
  height: 500px;
  display: block;
}

.tables_div {
  margin-top: 30px;
}

small {
  font-size: 10px;
}

.small_mmr {
  margin-left: 5px;
}


</style>
<script>
import {mapGetters} from "vuex";
import moment from "moment";

export default {
  name: 'AdminDashBoard',
  data() {
    return {
      clients: {
        pastDue: [],
        soonCanceled: [],
        nullRecurrency: []
      },
      metrics: [],
      require_editMetrics: false,
      ui: {
        loading: true,
        previous: {
          mmr: 0,
          issuedRevenue: 0,
          liquidRevenue: 0,
          nonPayment: 0,
          totalClients: 0,
          ticket: 0,
          lt: 0,
          ltv: 0,
          churningCumulative: 0,
          growthCumulative: 0,
          activatedCumulative: 0,
          rescuedCumulative: 0,
          canceledCumulative: 0,
          canceledRecurrencyCumulative: 0,
          activatedRecurrencyCumulative: 0,
          rescuedRecurrencyCumulative: 0,
          latePayersRecurrency: 0,
          latePayers: 0
        },
        current: {
          mmr: 0,
          issuedRevenue: 0,
          liquidRevenue: 0,
          nonPayment: 0,
          totalClients: 0,
          ticket: 0,
          lt: 0,
          ltv: 0,
          churningCumulative: 0,
          growthCumulative: 0,
          activatedCumulative: 0,
          rescuedCumulative: 0,
          canceledCumulative: 0,
          canceledRecurrencyCumulative: 0,
          activatedRecurrencyCumulative: 0,
          rescuedRecurrencyCumulative: 0,
          latePayersRecurrency: 0,
          latePayers: 0,
        },
      }
    }
  },
  mounted() {
    this.load()
  },

  computed: {
    ...mapGetters(['USER'])
  },

  methods: {
    async load() {
      const response = await this.request().get("Carregando informações", '/dashboard/admin')
      this.ui.current = response.data.current
      this.ui.previous = response.data.previous
      await Promise.all([this.pastDue(), this.soonCanceled(), this.nullRecurrencyActiveClients()])
      this.ui.loading = false
    },
    async pastDue() {
      const response = await this.request().get('Carregando os ultimos suspensos', `/clients/dashboard`, {
        query: {
          status: 2,
          due_to_gt: moment().subtract(50, 'days').format('YYYY-MM-DD'),
        },
        limit: 100,
        order: 'suspension_date',
        direction: 'desc'
      })
      this.clients.pastDue = response.data
    },
    async soonCanceled() {
      const response = await this.request().get('Carregando os proximos a serem cancelados', `/clients/dashboard`, {
        query: {
          status: 2,
          due_to_lt: moment().subtract(50, 'days').format('YYYY-MM-DD'),
        },
        limit: 15,
        order: 'due_to',
        direction: 'desc'
      })
      this.clients.soonCanceled = response.data;
    },
    async nullRecurrencyActiveClients() {
      const response = await this.request().get('Carregando clientes sem recorrência', `/clients/dashboard`, {
        query: {
          null_plan_value: true,
        },
        limit: 600,
        order: 'due_to',
        direction: 'desc',
      })
      this.clients.nullRecurrency = response.data;
    },
    async editMetrics() {
      this.metrics = [];
      this.metrics.push({})
      const response = await this.request().get('Carregando tabela de métricas', 'dashboard/admin/metrics')
      response.data.forEach(element => {
        let created_at = element.created_at
        let id = element.id
        this.metrics.push(JSON.parse(element.value))
        this.metrics[this.metrics.length - 1] = {
          ...this.metrics[this.metrics.length - 1],
          created_at,
          id
        }
      });
      this.require_editMetrics = true;
    }
  }
}
</script>
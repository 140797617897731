<template>
  <ui-loading :is-loading="ui.loading">
    <div class="row">
      <div class="col-md-12">
        <div style="margin-top: 20px" class="row">
          <ui-select class="vendor-select" @input="(id) => syncCurrentVendor(id)"
                     :disable-on-load="onloadingVendorClients === true || onloadingVendorMeetings === true"
                     :options="vendors" :current-key="current.vendor.id"
                     :label="'Vendedor:'"></ui-select>
          <div style="padding: 0 10px 0 0;" class="col-md-6">
            <div class="title clients-title">
              <h3 class="inline-block">Clientes de {{ current.vendor.name }}</h3>
              <input class="form-control search-clients-bar" type="text" v-model="search"
                     :placeholder=searchClientsPlaceholderText>
            </div>
            <div v-if="clients.length > 0 || onloadingVendorClients === true" class="table-area">
              <div class="table-container">
                <div v-if="onloadingVendorClients === true" class="loader"></div>
                <div v-else class="table-wrapper">
                  <div class="table-row heading">
                    <div style="min-width: 80px" class="hidden-xs row-item">Código</div>
                    <div class="row-item">Nome</div>
                  </div>
                  <div v-for="item in filteredClients" class="table-row">
                    <div style="min-width: 80px" class="hidden-xs row-item"><b>{{ item.code }}</b></div>
                    <div class="row-item"><a href="#" @click.prevent="openClient(item)">{{ item.name }}</a>
                      <small v-if="item.status === 4" style="margin-left: 3px;" class="alert-danger">pré-cliente</small>
                      <small v-if="item.status === 2" style="margin-left: 3px;" class="alert-warning">suspenso</small>
                    </div>
                  </div>
                  <ui-meeting-modal :vendorName="current.client.vendor_name" :webSiteDomain="webSiteDomain"
                                    :sciRelatedData="sciRelatedData" :client="current.client" :hasApp="appEnabled"
                                    v-if="showClient"
                                    @close="showClient = false">
                  </ui-meeting-modal>
                </div>
              </div>
            </div>
            <div v-else>
              <h3>Nenhum cliente vinculado a esse {{
                  Number(this.USER.occupation_id) !== 13 ? "usuário" : "vendedor"
                }}.</h3>
            </div>
          </div>
          <div style="padding: 0 10px 0 0;" class="col-md-6">
            <div class="title">
              <h3>Próximas Reuniões</h3>
            </div>
            <div v-if="meetings.length > 0 || onloadingVendorMeetings === true" class="table-area">
              <div class="table-container">
                <div v-if="onloadingVendorMeetings === true" class="loader"></div>
                <div v-else class="table-wrapper">
                  <div class="table-row heading">
                    <div style="min-width: 140px" class="row-item">Data/Hora</div>
                    <div style="min-width: 80px" class="hidden-xs row-item">Código</div>
                    <div class="row-item">Nome</div>
                  </div>
                  <div v-for="item in meetings" class="table-row">
                    <div class="row-item">{{ item.due_to | datetime(format = 'DD/MM/YYYY HH:mm') }}</div>
                    <div style="min-width: 80px" class="hidden-xs row-item"><b>{{ item.client.code }}</b></div>
                    <div class="row-item client-name"><a href="#" @click.prevent="openMeeting(item)">{{
                        item.client.name
                      }}</a><small style="margin-left: 3px;" class="alert-danger">
                      {{ item.client.status === 4 ? 'pré-cliente' : '' }}</small><small style="margin-left: 3px;"
                                                                                        class="alert-danger">
                      {{ item.client.status === 2 ? 'suspenso' : '' }}</small></div>
                  </div>
                  <ui-meeting-modal :vendorName="current.client.vendor_name" :webSiteDomain="webSiteDomain"
                                    :sciRelatedData="sciRelatedData" :meeting="true" :client="current.client"
                                    :hasApp="appEnabled"
                                    v-if="showMeeting"
                                    @close="showMeeting = false">
                  </ui-meeting-modal>
                </div>
              </div>
            </div>
            <div v-else>
              <h3>Nenhuma reunião agendada.</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ui-loading>
</template>
<style scoped>
.title {
  border-bottom: 1px solid #365F42;
  margin-bottom: 10px
}

.small_mmr {
  margin-left: 5px;
}

small, i {
  font-size: 12px !important;
}

.vendor-select {
  width: 20%;
}

.search-clients-bar {
  display: inline-block;
  width: 30%;
  left: 10px;
  position: relative;
}

.table-area {
  height: 500px;
  overflow-y: scroll;
}

.table-container {
  display: flex;
  flex-flow: column nowrap;
  background-color: white;
  width: 100%;
  margin: 0 auto;
  border-radius: 4px;
  border-bottom: 1px solid #DADADA;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .08);
}

.table-row {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  border-bottom: 1px solid #dadada;
}

.heading {
  position: sticky;
  border-bottom: 1px solid #dadada;
  top: 0;
  padding: 5px 0;
  background-color: #fff;
  color: #3e3e3e;
  font-weight: bold;
}

.row-item {
  font-size: 14px;
  padding: 8px 15px;
}

.row-item:last-child {
  border-bottom: 0;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin: 20px auto;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 991px) {
  h1, h2, h3, h4, h5, h6 {
    padding: 0;
  }

  div.col-md-3, div.col-md-5, div.col-md-7 {
    padding: 0;
  }

  div.col-md-3 {
    margin: 5px 0;
  }

  .row > div.col-md-6 {
    margin-bottom: 20px;
  }

  input.search-clients-bar {
    width: 45%;
  }

  .row-item.client-name {
    max-width: 150px;
  }

  .vendor-select {
    width: 50%;
  }

  .search-clients-bar {
    display: block;
    left: unset;
    margin: 5px 0;
  }

  .loader {
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #3498db; /* Blue */
    margin: 195px auto;
    width: 100px;
    height: 100px;
  }
}

</style>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'SDR',
  data() {
    return {
      search: "",
      clients: [],
      vendors: [],
      clients_plan_id: [],
      meetings: [],
      meeting: {},
      showClient: false,
      showMeeting: false,
      sciRelatedData: {},
      webSiteDomain: "",
      appEnabled: false,
      loadingVendorClients: {
        type: Boolean,
        default: false
      },
      loadingVendorMeetings: {
        type: Boolean,
        default: false
      },
      ui: {
        loading: true,
      },
      current: {
        client: {},
        vendor: {
          id: {
            type: Number,
          },
          name: {
            type: String
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters(['USER']),
    filteredClients() {
      return this.clients.filter(client => {
        return (client.name.toLowerCase().match(this.search.toLowerCase()) || client.code.match(this.search.toLowerCase()))
      })
    },
    searchClientsPlaceholderText() {
      return window.matchMedia('(max-width: 991px)').matches ? 'Código/Nome' : 'Digite um código ou nome.';
    },
    onloadingVendorMeetings() {
      return this.loadingVendorMeetings === true;
    },
    onloadingVendorClients() {
      return this.loadingVendorClients === true;
    }
  },
  mounted() {
    this.current.vendor.id = this.USER.id;
    this.current.vendor.name = this.USER.name;
    this.load();
  },
  methods: {
    async load() {
      await Promise.all([
        this.getVendors(),
        this.getClients(),
        this.getNotes(),
      ]).then(() => {
        this.ui.loading = false;
      });
    },
    async getVendors() {
      const response = await this.request().get("Carregando vendedores", '/users', {
        query: {
          vendors: true
        }
      })
      this.vendors = response.data;
      let vendors_ids = [];
      this.vendors.forEach((vendor) => {
        vendors_ids.push(vendor.id);
      });
      const allVendors = {
        id: vendors_ids.join(','),
        name: "Todos"
      };
      this.vendors.push(allVendors);
    },
    async getClients() {
      this.loadingVendorClients = true;
      const response = await this.request().get("Carregando clientes", "/clients/dashboard", {
        query: {
          vendor_id: this.current.vendor.id,
          status: [1, 2, 4]
        },
        limit: String(this.current.vendor.name) === "Todos" || String(this.current.vendor.name) === "Site" ? 10000 : 500,
      })
      this.clients = [];
      response.data.forEach((clients) => {
        this.clients.push(clients)
      })
      this.loadingVendorClients = false;
    },
    async getNotes() {
      this.loadingVendorMeetings = true;
      const response = await this.request().get("Carregando próximas reuniões", `dashboard/vendor/meetings/${this.current.vendor.id}`)
      this.meetings = response.data
      this.loadingVendorMeetings = false;
    },
    async getClientById(meeting = false) {
      const response = await this.request().get(`Carregando dados de cliente`,
          `/clients/${this.current.client.id}/summary`)
      this.current.client = response.data
      this.current.client.vendor_name = this.vendors.find(vendor => Number(vendor.id) === Number(response.data.vendor_id)).name;
      this.sciRelatedData = response.data.sci
      this.webSiteDomain = response.data.domain !== null ? response.data.domain : response.data.imobDomain
      this.checkIfHasApp(response)
      if (meeting !== false) {
        this.current.client = {
          ...this.current.client,
          ...meeting
        }
        return this.showMeeting = !this.showMeeting
      }
      return this.showClient = !this.showClient
    },
    async openMeeting(meeting) {
      this.current.client = {
        ...meeting,
        name: meeting.client.name,
        id: meeting.client.id,
      }
      await this.getClientById(meeting)
    },
    async openClient(item) {
      this.current.client = item
      await this.getClientById()
    },
    checkIfHasApp(client) {
      this.appEnabled = false
      client.data.plans.forEach(plan => {
        plan.clientProducts.forEach(clientProduct => {
          clientProduct.configs.forEach(config => {
            if (config.name === "app_enabled" && config.value === "1") {
              this.appEnabled = true
            }
          })
        })
      })
    },
    syncCurrentVendor(id) {
      this.current.vendor.id = id;
      this.current.vendor.name = this.vendors.find(vendor => String(vendor.id) === String(id)).name;
      this.load();
    },
  }
}
</script>
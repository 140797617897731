<template>
  <ui-loading :is-loading="ui.loading">
    <div class="row">
      <div class="col-md-12">
        <div class="portlet light">
          <div class="portlet-title">
            <div class="caption caption-md">
              <i class="icon-bar-chart theme-font hide"></i>
              <span class="caption-subject theme-font bold uppercase">
                <i class="icon-users"></i> Clientes
              </span>
            </div>
            <div class="actions btn-set">
              <router-link
                v-can="'create-client'"
                :to="{name: 'clients.create'}"
                class="btn btn-success"
              >
                <i class="fa fa-plus"></i> Cadastrar Novo
              </router-link>
            </div>
          </div>
          <div class="portlet-body">
            <form action @submit.prevent="doSearch">
              <div class="row">
                <div class="col-lg-6">
                  <ui-select
                    :options="ui.options.customers"
                    v-model="ui.search.customer_id"
                    label="Customer"
                  ></ui-select>
                </div>
                <div class="col-lg-3">
                  <ui-select
                    :options="ui.options.vendors"
                    v-model="ui.search.vendor_id"
                    label="Vendedor"
                  ></ui-select>
                </div>
                <div class="col-md-3">
                  <ui-text
                    v-model="ui.search.email_login"
                    label="Filtrar E-mail Login"
                    placeholder="Filtre pelos e-mails usados no login"
                  ></ui-text>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <ui-text
                    v-model="ui.search.text"
                    label="Filtrar"
                    placeholder="Filtre por: Nome, Código, E-mail, Dominio."
                  ></ui-text>
                </div>
                <div class="col-lg-2">
                  <ui-select
                    :options="ui.options.statusClassification"
                    v-model="ui.search.status_classification"
                    label="Clas. Status"
                  ></ui-select>
                </div>

                <div class="col-md-2">
                  <ui-text v-model="ui.search.state" label="Estado" placeholder="Estado"></ui-text>
                </div>
                <div class="col-md-2">
                  <ui-text v-model="ui.search.city" label="Cidade" placeholder="Cidade"></ui-text>
                </div>

                <div class="col-md-2">
                  <ui-text v-model="ui.search.neighborhood" label="Bairro" placeholder="Bairro"></ui-text>
                </div>

                <div class="col-md-2">
                  <ui-select
                    :options="ui.options.arrears_states"
                    label="Clientes Em"
                    v-model="ui.search.in_arrears"
                  ></ui-select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <ui-select
                    :options="ui.options.models"
                    label="Modelo"
                    label-name="label"
                    v-model="ui.search.model"
                  ></ui-select>
                </div>
                <div class="col-md-2">
                  <client-score-select v-model="ui.search.scores"></client-score-select>
                </div>
                <div class="col-lg-6">
                  <label for>Status</label>
                  <br />
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-default status_select"
                      @click="clearStatus"
                      :class="{'active' : ui.search.status === null}"
                    >Todos</button>
                    <button
                      type="button"
                      class="btn btn-default status_select"
                      v-for="(item, index) in ui.options.statusOptions"
                      :key="index"
                      @click="addStatus(item.id)"
                      :class="{'active': ui.search.status[item.id] !== undefined}"
                    >{{item.name}}</button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12" style="margin-top: 15px">
                  <button type="submit" class="btn btn-primary">Buscar</button>
                </div>
              </div>
            </form>
            <hr />
            <button class="btn" @click.prevent="print">
              <i class="fa fa-file-excel-o"></i>
            </button>
            <div class="row" ref="searchTable">
              <ui-pagination :pagination="ui.pagination" @paginate="toPage"></ui-pagination>
              <div class="col-md-12">
                <table class="table table-striped table-hover" id="client-index">
                  <thead>
                    <tr>
                      <th>Cliente</th>
                      <th>Contatos</th>
                      <th>Status</th>
                      <th>Clas. Status</th>
                      <th>Plano</th>
                      <th>CS</th>
                      <th>Vendedor</th>
                      <th>Cidade</th>
                      <th>Dominio</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in ui.items" :key="index">
                      <td>
                        <div class="photo-person-list">
                          <img alt class="img-circle" src="@assets/imgs/avatar-realestate.jpg" />
                        </div>

                        <div>
                          <span class="colaborador-nome">
                            <router-link
                              :to="{name: 'clients.edit.index', params: {id: item.id}} "
                            >{{item.name}}</router-link>
                            <a :href="`http://${item.code}.imob.online`" target="_blank">
                              <i class="fa fa-external-link" />
                            </a>
                          </span>
                          <div style="margin-top:10px;">
                            <span class="label label-info">{{item.code}}</span>
                            <span class="label label-success" v-if="!item.in_debt">Pagamento em Dia!</span>
                            <span v-else>
                              <span class="label label-danger past_due">Em Débito!</span>
                              <span class="label label-danger past_due">{{item.days_past_due}}d</span>
                            </span>

                            <span
                              title="Atrasos"
                              v-if="item.non_payment_rate && item.non_payment_rate > 0"
                              :class="{
                                                    'label-danger': item.non_payment_rate > 50,
                                                    'label-warning': item.non_payment_rate > 30 && item.non_payment_rate <= 50,
                                                    'label-success': item.non_payment_rate <= 30
                                                    }"
                              class="label"
                            >
                              <i class="fa fa-thumbs-down" style="color: inherit"></i>
                              {{item.non_payment_rate}}%
                            </span>
                            &nbsp;
                            <span
                              class="label label-info"
                              v-if="item.sci !== null && item.sci.total_usage > 0"
                              title="Uso do plano"
                            >
                              <i class="fa fa-retweet"></i>
                              {{ Math.floor(item.sci.total_usage) }}%
                            </span>
                            <client-score
                              style="margin-left: 10px"
                              :score="item.score"
                              title="Score Sci-Online"
                            ></client-score>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span v-if="item.email">
                          <i class="fa fa-envelope-o"></i>
                          {{item.email}}
                        </span>
                        <br />

                        <span v-if="item.phone1">
                          <i class="fa fa-phone"></i>
                          {{item.phone1}}
                          <br />
                        </span>

                        <span v-if="item.phone2">
                          <i class="fa fa-phone"></i>
                          {{item.phone2}}
                          <br />
                        </span>
                      </td>

                      <td>{{item.statusInfo.name}}</td>

                      <td>
                        <span
                          v-if="item.statusClassificationInfo"
                        >{{item.statusClassificationInfo.name}}</span>
                        <span v-else></span>
                      </td>
                      <td>
                        <span v-if="item.plan !== null">{{item.plan.name}}</span>
                      </td>
                      <td>
                        <span v-if="! item.customer">Sem CS</span>
                        <span v-else>{{item.customer.name}}</span>
                      </td>
                      <td>
                        <span v-if="! item.customer">Sem Vendedor</span>
                        <span v-else>{{item.vendor.name}}</span>
                      </td>

                      <td>
                        {{item.uf}} - {{item.city}}
                        <br />
                        {{item.neighborhood}}
                      </td>
                      <td>
                        <a v-if="item.domain" target="_blank" :href="item.domain">{{item.domain}}</a>
                        <span v-else>Sem Dominio!</span>
                        <br />
                        {{item.model}}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ui-pagination :pagination="ui.pagination" @paginate="toPage"></ui-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ui-loading>
</template>

<script>
import encoding from "encoding-japanese";
import _ from "lodash";
import clientScore from "@components/client/Score.vue";
import clientScoreSelect from "@components/client/ScoreSelect.vue";

export default {
  name: "client-list",
  components: {
    clientScore,
    clientScoreSelect
  },
  data() {
    return {
      ui: {
        loading: true,
        options: {
          customers: [],
          statusOptions: [],
          vendors: [],
          statusClassification: []
        },
        search: {
          customer_id: null,
          vendor_id: null,
          status_classification: null,
          status: {},
          scores: [],
          model: null,
          ids: []
        },
        pagination: {
          page: 0,
          per_page: 50
        },
        items: []
      }
    };
  },

  mounted() {
    this.getOptions()
      .then(this.getCustomers)
      .then(this.getVendors)
      .then(() => {
        this.ui.options.arrears_states = [
          {
            id: 1,
            name: "Em Atraso"
          },
          {
            id: 2,
            name: "Em Dia"
          }
        ];
      })
      .then(() => (this.ui.loading = false))
      .then(this.populateSearch)
      .then(this.search);
  },

  beforeRouteUpdate(to, from, next) {
    this.ui.search = to.query;
    console.log(this.ui.search, to.query);
    this.search();

    return next();
  },

  created() {
    if (this.ui.loading === false) {
      this.search();
    }
  },

  methods: {
    AddScore(min, max) {
      if (this.search.score.min === min) {
        this.ui.search.score.min = null;
        this.ui.search.score.max = null;
        return;
      }
      this.ui.search.score.min = min;
      this.ui.search.score.max = max;
    },
    populateSearch() {
      _.forEach(this.ui.search, (item, key) => {
        this.$set(this.ui.search, key, _.get(this.$route.query, key, item));
      });
    },
    print() {
      const filters = _.reduce(
        this.ui.search,
        (carry, item, key) => {
          if (item !== null && item !== "") {
            carry[key] = item;
          }

          return carry;
        },
        {}
      );
      this.request()
        .get("Gerando relatorio", "clients/report", {
          query: filters
        })
        .then(response => {
          var blob = new Blob([response.data], { type: "text/csv" });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "report.csv";

          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
        });
    },

    addStatus(status) {
      if (this.hasStatus(status)) {
        this.$delete(this.ui.search.status, status);
        return;
      }
      this.$set(this.ui.search.status, status, status);
    },
    clearStatus() {
      this.ui.search.status = {};
    },
    hasStatus(status) {
      return _.has(this.ui.search.status, status);
    },
    status(item) {
      return _.find(this.ui.options.statusOptions, i => i.id == item.status)
        .name;
    },

    customer(item) {
      _.find(this.ui.options.customers, i => i.id == item.customer_id);
    },

    getCustomers() {
      return this.request()
        .get("Carregando Customers", `/users`, {
          query: {
            customers: true
          },
          order: "name"
        })
        .then(response => (this.ui.options.customers = response.data));
    },

    getOptions() {
      return this.request()
        .get("Carregando Opções", `/clients/options`)
        .then(response => {
          this.ui.options = response.data;
        });
    },

    getVendors() {
      return this.request()
        .get("Carregando Customers", `/users`, {
          query: {
            vendors: true
          },
          order: "name"
        })
        .then(response => (this.ui.options.vendors = response.data));
    },

    search() {
      const filters = _.reduce(
        this.ui.search,
        (carry, item, key) => {
          if (item !== null && item !== "") {
            carry[key] = item;
          }

          return carry;
        },
        {}
      );
      this.request()
        .block(this.$refs.searchTable)
        .get("filtrando", `/clients`, {
          query: filters,
          page: this.ui.pagination.page,
          limit: 50
        })
        .then(response => {
          this.ui.items = response.data;
          this.ui.pagination = response.meta.pagination;
        });
    },

    doSearch() {
      this.ui.paginations = 0;
      this.search();
    },

    toPage(page) {
      this.ui.pagination.page = page;
      this.search();
    }
  }
};
</script>


<template>
  <div>
    <div>
      <form role="form" action="#" id="save-client" data-toggle="validator" ref="form">
        <div class="row">
          <div class="col-md-2">
            <ui-select
              :required="true"
              label="Status"
              :options="options.statusOptions"
              name="status"
              v-model="current.status"
            ></ui-select>
          </div>
          <div class="col-md-2">
            <ui-text v-model="current.code" name="code" label="Código" :disabled="true"></ui-text>
          </div>

          <div class="col-md-1">
            <ui-text v-model="current.sl_id" :disabled="true" label="SL ID"></ui-text>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            Data de Cadastro
            <br />
            <strong>{{current.created_at | date}}</strong>
          </div>
          <div class="col-md-2">
            Data de ativação:
            <br />
            <strong>{{current.activated_at | date}}</strong>
          </div>
          <div class="col-md-2">
            <ui-date label="Data de Cancelamento" name="cancel_date" v-model="current.cancel_date"></ui-date>
          </div>
          <div class="col-md-2">
            <ui-date
              v-model="current.suspension_date"
              name="suspension_date"
              label="Data de suspensão"
            ></ui-date>
          </div>
        </div>

        <div class="row">
          <div class="col-md-2 vertical-checkbox">
            <ui-check
              label="Desabilitar mensalidade"
              :true-value="1"
              :value="current.disable_monthly"
              v-model="current.disable_monthly"
            ></ui-check>
          </div>
          <div class="col-md-2 vertical-checkbox">
            <ui-check :value="current.migrated" v-model="current.migrated" label="Cliente Migrado"></ui-check>
          </div>
          <div class="col-md-2 vertical-checkbox">
            <ui-check
              label="Ignorar Bloqueio"
              :true-value="1"
              :value="current.ignore_block"
              v-model="current.ignore_block"
            ></ui-check>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <ui-text
              label="Nome / Nome Fantasia"
              :required="true"
              name="name"
              v-model="current.name"
            ></ui-text>
          </div>
          <div class="col-md-2">
            <ui-text
              :required="true"
              label="Razão Social"
              name="company_name"
              v-model="current.company_name"
            ></ui-text>
          </div>

          <div class="col-md-2">
            <ui-date v-model="current.due_to" name="due_to" label="Próximo Vencimento"></ui-date>
            <!-- <ui-text v-model="next_billing_date" name="due_to" label="Proximo vencimento" :disabled="true"></ui-text> -->
          </div>

          <div class="col-md-2">
            <ui-text
              v-model="current.recurrency"
              :disabled="true"
              name="recurrency"
              label="Recorrencia"
            ></ui-text>
          </div>
        </div>

        <div class="row">
          <div class="col-md-2">
            <ui-text v-model="current.creci" label="CRECI"></ui-text>
          </div>
          <div class="col-md-1">
            <ui-text v-model="current.creci_uf" label="UF"></ui-text>
          </div>
          <div class="col-md-3">
            <ui-text
              :required="true"
              label="CPF/CNPJ"
              name="cpf_cnpj"
              mask="cpf_cnpj"
              v-model="current.cpf_cnpj"
            ></ui-text>
          </div>
          <div class="col-md-3">
            <ui-text label="I.E." name="state_registration" v-model="current.state_registration"></ui-text>
          </div>
          <div class="col-md-3">
            <ui-text label="RG" name="rg" mask="rg" v-model="current.rg"></ui-text>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <ui-text :required="true" label="E-mail" name="email" v-model="current.email"></ui-text>
          </div>
          <div class="col-md-3">
            <ui-text
              :required="true"
              label="E-mails de contato (separados por ';')"
              name="contact_emails"
              v-model="current.contact_emails"
            ></ui-text>
          </div>
          <div class="col-md-3">
            <ui-text
              :required="true"
              label="Telefone 1"
              name="phone1"
              mask="phone"
              v-model="current.phone1"
            ></ui-text>
          </div>
          <div class="col-md-3">
            <ui-text label="Telefone 2" mask="phone" v-model="current.phone2"></ui-text>
          </div>
        </div>
        <h4>Endereço</h4>
        <hr />
        <div ref="addressCep">
          <div class="row">
            <div class="col-md-3">
              <ui-text
                :input-group="true"
                :required="true"
                label="CEP"
                name="cep"
                mask="cep"
                v-model="current.cep"
              >
                <span class="input-group-btn">
                  <button class="btn green" @click="populateAddress" type="button">
                    <i class="fa fa-search"></i>
                  </button>
                </span>
              </ui-text>
            </div>
            <div class="col-md-9">
              <ui-text :required="true" label="Endereço" name="address" v-model="current.address"></ui-text>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <ui-text :required="true" label="Número" name="number" v-model="current.number"></ui-text>
            </div>
            <div class="col-md-2">
              <ui-text label="Complemento" name="complement" v-model="current.complement"></ui-text>
            </div>
            <div class="col-md-3">
              <ui-text
                :required="true"
                label="Bairro"
                name="neighborhood"
                v-model="current.neighborhood"
              ></ui-text>
            </div>
            <div class="col-md-3">
              <ui-text :required="true" label="Cidade" name="city" v-model="current.city"></ui-text>
            </div>
            <div class="col-md-2">
              <ui-text :required="true" label="UF" name="uf" v-model="current.uf"></ui-text>
            </div>
          </div>
        </div>
        <h4>Outras Informações</h4>
        <hr />
        <div class="row">
          <div class="col-md-2">
            <ui-select
              label="Dia de Cobrança"
              :options="options.paymentDays"
              :required="true"
              name="paymentDays"
              v-model="current.billing_day"
            ></ui-select>
          </div>
          <div class="col-md-4">
            <ui-select
              label="Vendedor"
              :options="options.vendors"
              :option-is-disabled="isDisabled"
              :requred="true"
              name="vendor_id"
              v-model="current.vendor_id"
            ></ui-select>
          </div>
          <div class="col-md-3">
            <ui-select
              label="Customer Success"
              :options="options.customers"
              :required="true"
              v-model="current.customer_id"
              name="customer_id"
              :option-is-disabled="isDisabled"
            ></ui-select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <ui-textarea label="Observações" v-model="current.obs"></ui-textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button v-if="current.id" type="button" class="btn btn-default" @click="resetValues">
            Resetar
            Valores
          </button>
          <button
            v-can="'edit-client'"
            type="button"
            class="btn btn-primary"
            @click="updateClient"
          >Salvar</button>
        </div>
      </form>
    </div>
    <ui-modal v-if="ui.require_note" @close="ui.require_note = false" ref="noteModal">
      <div slot="header">
        <h4>Cadastro de nota de informação</h4>
      </div>
      <div slot="body">
        <form ref="noteForm" action @submit.prevent="doSendNote">
          <ui-text v-model="note.title" label="Titulo" :disabled="true"></ui-text>

          <ui-select
            :options="notesTypes"
            v-model="note.type_id"
            keyName="id"
            label="motivo"
            :class="{'has-error' : ui.noteError}"
          ></ui-select>
          <div v-if="ui.noteError">
            <span class="text-danger">O Motivo da alteração de status é obrigatório.</span>
          </div>
          <ui-textarea placeholder="Motivo" v-model="note.description"></ui-textarea>
        </form>
      </div>
      <div slot="footer">
        <div class="pull-right">
          <button class="btn btn-default" @click="ui.require_note = false" type="submit">Cancelar</button>
          <button :disabled="ui.sendingNote" class="btn blue" @click="doSendNote">Salvar</button>
        </div>
      </div>
    </ui-modal>
  </div>
</template>

<script>
import _ from "underscore";
import moment from "moment";
import CLIENT from "constants";
import Client from "@lib/models/clients";

export default {
  name: "formClient",
  data() {
    return {
      current: _.clone(this.client),
      note: {
        title: "",
        description: "",
        type_id: null
      },
      ui: {
        require_note: false,
        noteError: false,
        sendingNote: false
      }
    };
  },
  props: {
    code: {
      default: ""
    },
    client: {
      type: Object,
      required: true
    },

    options: {
      type: Object,
      required: true
    }
  },
  computed: {
    notesTypes() {
      let d = _.map(this.options.note.types, item => {
        if (item.statuses.indexOf(parseInt(this.current.status)) >= 0) {
          return item;
        }
      });

      let filtered = _.filter(d, i => typeof i !== "undefined");

      return _.sortBy(filtered, "name");
    },

    createdDate() {
      if (this.current.created_at) {
        return moment(this.current.created_at).format("DD/MM/YYYY");
      }
    },
    next_billing_date() {
      if (
        this.current.next_billing_date === null ||
        !this.current.next_billing_date
      ) {
        return "";
      }

      if (this.current.next_billing_date.date === undefined) {
        return "";
      }

      return moment(this.current.next_billing_date.date).format("DD/MM/YYYY");
    }
  },

  mounted() {
    this.resetValues();
  },
  watch: {
    client: function(newValue) {
      this.resetValues(newValue);
    }
  },

  methods: {
    resetValues(value) {
      if (!value) {
        value = this.client;
      }
      const values = _.clone(value);
      const keys = _.keys(values);

      for (var i of keys) {
        this.$set(this.current, i, values[i]);
      }
    },

    correctNoteType() {
      let index = {
        "41": 3,
        "12": 4,
        "23": 4,
        "34": 14
      };

      let myIndex = "" + this.client.status + this.current.status;

      if (_.has(index, myIndex)) {
        return index[myIndex];
      }
    },

    updateClient() {
      if (this.client.status === this.current.status || !this.current.id) {
        this.doUpdateClient();
        return;
      }

      this.sendNote();
    },

    needNote() {
      if (
        this.client.status === this.current.status ||
        !this.current.id ||
        (parseInt(this.client.status) === CLIENT.STATUS.SUSPENSO &&
          this.current.status !== CLIENT.STATUS.CANCELADO)
      ) {
        return false;
      }

      return true;
    },

    sendNote() {
      this.request()
        .block(this.$refs.form)
        .post("Validando o cliente", `clients/validate`, this.current)

        .then(response => {
          this.note.title =
            "Mudando status para " +
            _.filter(
              this.options.statusOptions,
              i => i.id == this.current.status
            )[0].name;
          this.note.activity_id = this.options.note.id;
          this.note.type_id = this.correctNoteType();
          this.ui.require_note = true;
        });
    },

    doSendNote() {
      this.ui.sendingNote = true;
      if (this.current.status === "1" && !this.note.type_id) {
        this.ui.noteError = true;
        return;
      }
      this.request()
        .block(this.$refs.noteForm)
        .post("Salvando nota", `clients/${this.client.id}/notes`, this.note)
        .then(response => {
          this.ui.sendingNote = false;
          this.current.note_id = response.data.id;
          this.ui.require_note = false;

          this.doUpdateClient();
        });
    },

    doUpdateClient() {
      let request = this.request().block(this.$refs.form);

      let toSend = _.omit(this.current, [
        "plans",
        "users",
        "vendor",
        "customer"
      ]);

      if (this.current.id) {
        request = request.put(
          "Atualizando Cliente",
          `/clients/${this.current.id}`,
          toSend
        );
      } else {
        request = request.post("Salvando o cliente", `/clients`, toSend);
      }

      return request.then(response => {
        this.$emit("change", _.clone(response.data));
      });
    },

    isDisabled(item) {
      return item.status !== 1;
    },

    populateAddress() {
      this.searchCep(this.current.cep, this.$refs.addressCep).then(address => {
        this.current.address = address.street;
        this.current.neighborhood = address.neighborhood;
        this.current.city = address.city;
        this.current.uf = address.uf;
        this.current.number = "";
        this.current.complement = "";

        this.$set(this.current, this.current);
      });
    },

    searchCep(cep, element) {
      return new Promise((resolve, reject) => {
        this.request()
          .block(element)
          .get("Carregando Endereço", `utils/address/${cep.replace(/\D/g, "")}`)
          .then(response => {
            resolve(response.data);
          });
      });
    }
  }
};
</script>

<style scoped>
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"col-md-4"},[_c('p',[_vm._v("\n                Quantidade de Clientes Ativos: "+_vm._s(_vm.customer.current.active_count)+"\n                "),_c('i',{staticClass:"fa",class:{
                    'fa-arrow-circle-o-up text-success': _vm.customer.current.active_count > _vm.customer.previous.active_count,
                    'fa-arrow-circle-o-down text-danger': _vm.customer.current.active_count < _vm.customer.previous.active_count,
                    'fa-minus-circle text-info': _vm.customer.current.active_count === _vm.customer.previous.active_count
                   },attrs:{"title":'Mes passado '+ _vm.customer.previous.active_count}})])]),_vm._v(" "),_c('div',{staticClass:"col-md-4"},[_c('p',[_vm._v("\n                Quantidade de Clientes Suspensos: "+_vm._s(_vm.customer.current.suspended_count)+"\n                "),_c('i',{staticClass:"fa",class:{
                    'fa-arrow-circle-o-up text-danger': _vm.customer.current.suspended_count > _vm.customer.previous.suspended_count,
                    'fa-arrow-circle-o-down text-success': _vm.customer.current.suspended_count < _vm.customer.previous.suspended_count,
                    'fa-minus-circle text-info': _vm.customer.current.suspended_count === _vm.customer.previous.suspended_count
                   },attrs:{"title":'Mes passado '+ _vm.customer.previous.suspended_count}})])]),_vm._v(" "),_c('div',{staticClass:"col-md-4"},[_c('p',[_vm._v("\n                Quantidade de Clientes Cancelados: "+_vm._s(_vm.customer.current.canceled_count)+"\n                "),_c('i',{staticClass:"fa",class:{
                    'fa-arrow-circle-o-up text-danger': _vm.customer.current.canceled_count > _vm.customer.previous.canceled_count,
                    'fa-arrow-circle-o-down text-success': _vm.customer.current.canceled_count < _vm.customer.previous.canceled_count,
                    'fa-minus-circle text-info': _vm.customer.current.canceled_count === _vm.customer.previous.canceled_count
                   },attrs:{"title":'Mes passado '+ _vm.customer.previous.canceled_count}})])]),_vm._v(" "),_c('div',{staticClass:"col-md-4"},[_c('p',[_vm._v("Valor Ativo "+_vm._s(_vm._f("money")(_vm.customer.current.total_active_amount))+"\n\n                "),_c('i',{staticClass:"fa",class:{
                    'fa-arrow-circle-o-up text-success': _vm.customer.current.total_active_amount > _vm.customer.previous.total_active_amount,
                    'fa-arrow-circle-o-down text-danger': _vm.customer.current.total_active_amount < _vm.customer.previous.total_active_amount,
                    'fa-minus-circle text-info': _vm.customer.current.total_active_amount === _vm.customer.previous.total_active_amount
                   },attrs:{"title":'Mes passado '+ _vm.customer.previous.total_active_amount}})])]),_vm._v(" "),_c('div',{staticClass:"col-md-4"},[_vm._v("\n            Valor Suspenso "+_vm._s(_vm._f("money")(_vm.customer.current.total_suspended_amount))+"\n\n            "),_c('i',{staticClass:"fa",class:{
                    'fa-arrow-circle-o-up text-danger': _vm.customer.current.total_suspended_amount > _vm.customer.previous.total_suspended_amount,
                    'fa-arrow-circle-o-down text-success': _vm.customer.current.total_suspended_amount < _vm.customer.previous.total_suspended_amount,
                    'fa-minus-circle text-info': _vm.customer.current.total_suspended_amount === _vm.customer.previous.total_suspended_amount
                   },attrs:{"title":'Mes passado '+ _vm.customer.previous.total_suspended_amount}})]),_vm._v(" "),_c('div',{staticClass:"col-md-4"},[_vm._v("\n            Valor Cancelado "+_vm._s(_vm._f("money")(_vm.customer.current.total_canceled_amount))+"\n\n            "),_c('i',{staticClass:"fa",class:{
                    'fa-arrow-circle-o-up text-danger': _vm.customer.current.total_canceled_amount > _vm.customer.previous.total_canceled_amount,
                    'fa-arrow-circle-o-down text-success': _vm.customer.current.total_canceled_amount < _vm.customer.previous.total_canceled_amount,
                    'fa-minus-circle text-info': _vm.customer.current.total_canceled_amount === _vm.customer.previous.total_canceled_amount
                   },attrs:{"title":'Mes passado '+ _vm.customer.previous.total_canceled_amount}})])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_vm._m(1),_vm._v(" "),_c('div',[_c('table',{ref:"listTrials",staticClass:"table",staticStyle:{"border-top":"none"}},[_vm._m(2),_vm._v(" "),_c('tbody',_vm._l((_vm.clients.toDue),function(item,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(item.code))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.name))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.days_to_due))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm._f("date")(item.due_to)))]),_vm._v(" "),_c('td',[_c('router-link',{attrs:{"to":{name: 'clients.edit.index', params: {id: item.id}}}},[_vm._v("\n                                Mais detalhes\n                            ")])],1)])}),0)])])]),_vm._v(" "),_c('div',{staticClass:"col-md-6"},[_vm._m(3),_vm._v(" "),_c('div',[_c('table',{ref:"listTrials",staticClass:"table",staticStyle:{"border-top":"none"}},[_vm._m(4),_vm._v(" "),_c('tbody',_vm._l((_vm.clients.pastDue),function(item,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(item.code))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.name))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.days_past_due)+" dias")]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm._f("date")(item.due_to)))]),_vm._v(" "),_c('td',[_c('router-link',{attrs:{"to":{name: 'clients.edit.index', params: {id: item.id}}}},[_vm._v("\n                                Mais detalhes\n                            ")])],1)])}),0)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"title"},[_c('h3',[_vm._v("Dados Rapidos")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('h3',[_vm._v("Proximos a Serem suspensos")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Código")]),_vm._v(" "),_c('th',[_vm._v("Nome")]),_vm._v(" "),_c('th',[_vm._v("Suspende em")]),_vm._v(" "),_c('th',[_vm._v("Data")]),_vm._v(" "),_c('th')])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('h3',[_vm._v("Ultimos suspensos")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Código")]),_vm._v(" "),_c('th',[_vm._v("Nome")]),_vm._v(" "),_c('th',[_vm._v("Suspendido a")]),_vm._v(" "),_c('th')])])}]

export { render, staticRenderFns }
<template>
  <ui-modal :width="'95%'" @close="$emit('close')">
    <div slot="header">
      <h1>Métricas dos últimos 30 dias</h1>
    </div>
    <div slot="body">
      <div class="row" style="overflow: scroll; height: 500px; padding: 0px 20px;">
        <table class="table metrics" style="border-top: none">
          <thead>
          <tr>
            <th>Data</th>
            <th>Inadimplencia (%)</th>
            <th>Total de clientes ativos</th>
            <th>Ativados (dia)</th>
            <th>Ativados (mês)</th>
            <th>Resgatados (dia)</th>
            <th>Resgatados (mês)</th>
            <th>Cancelados (dia)</th>
            <th>Cancelados (mês)</th>
            <th>Atrasados (suspensos)</th>
            <th v-bind:title="'Quantidade de clientes cancelados até o dia de hoje'">LT qtd <i
                class="fa fa-question-circle"
                aria-hidden="true"></i></th>
            <th v-bind:title="'MMR dos clientes cancelados até o dia de hoje'">LTV Rec. <i
                class="fa fa-question-circle"
                aria-hidden="true"></i></th>
            <th v-bind:title="'Soma do tempo dentro do sistema dos clientes'">LT tempo <i
                class="fa fa-question-circle"
                aria-hidden="true"></i></th>
            <th v-bind:title="'(LT tempo / LT qtd)'">LT <i class="fa fa-question-circle" aria-hidden="true"></i>
            </th>
            <th v-bind:title="'(LTV Recorrência / LT qtd)'">LTV <i class="fa fa-question-circle" aria-hidden="true"></i>
            </th>
            <th>MMR</th>
            <th v-bind:title="'(MMR / quantidade de clientes ativos e suspensos)'">Ticket medio <i
                class="fa fa-question-circle" aria-hidden="true"></i></th>
            <th v-bind:title="'Faturamento emitido.'">Faturamento Emit. <i class="fa fa-question-circle"
                                                                           aria-hidden="true"></i></th>
            <th v-bind:title="'Faturamento liquidado.'">Faturamento Liq. <i class="fa fa-question-circle"
                                                                            aria-hidden="true"></i></th>
            <th v-bind:title="'(MMR Cancelados do dia - Upsell do dia / MMR do primeiro dia do mês * 100 )'">Churning
              (%) (dia) <i class="fa fa-question-circle" aria-hidden="true"></i></th>
            <th v-bind:title="'(MMR Cancelados do mês - Upsell do mês / MMR do primeiro dia do mês * 100 )'">Churning
              (%) (mês) <i class="fa fa-question-circle" aria-hidden="true"></i></th>
            <th v-bind:title="'(MMR Ativados do dia / MMR do primeiro dia do mês * 100)'">Growth (dia) <i
                class="fa fa-question-circle" aria-hidden="true"></i></th>
            <th v-bind:title="'(MMR Ativados do mês / MMR do primeiro dia do mês * 100)'">Growth (mês) <i
                class="fa fa-question-circle" aria-hidden="true"></i></th>
            <th>Upsell</th>
            <th>MMR Cancelados (dia)</th>
            <th>MMR Cancelados (mês)</th>
            <th>MMR Ativados (dia)</th>
            <th>MMR Ativados (mês)</th>
            <th>MMR Resgatados (dia)</th>
            <th>MMR Resgatados (mês)</th>
            <th>MMR Atrasados (mês)</th>
            <th></th>
          </tr>
          </thead>
          <tbody style="overflow: hidden">
          <tr @click="" v-for="(item, key) in metrics" v-bind:key="key">
            <td v-if="item.id !== undefined"><label>{{ item.created_at | date }}</label></td>
            <td v-else>
              <ui-date v-model="item.created_at"></ui-date>
            </td>
            <td><input type="text" v-model="item.nonPayment"></td>
            <td><input type="text" v-model="item.totalClients"></td>
            <td><input type="text" v-model="item.activated"></td>
            <td><input type="text" v-model="item.activatedCumulative"></td>
            <td><input type="text" v-model="item.rescued"></td>
            <td><input type="text" v-model="item.rescuedCumulative"></td>
            <td><input type="text" v-model="item.canceled"></td>
            <td><input type="text" v-model="item.canceledCumulative"></td>
            <td><input type="text" v-model="item.latePayers"></td>
            <td><input type="text" v-model="item.ltQtd"></td>
            <td><input type="text" v-model="item.ltvRecurrency"></td>
            <td><input type="text" v-model="item.ltTiming"></td>
            <td><input type="text" v-model="item.lt"></td>
            <td><input type="text" v-model="item.ltv"></td>
            <td><input type="text" v-model="item.mmr"></td>
            <td><input type="text" v-model="item.ticket"></td>
            <td><input type="text" v-model="item.issuedRevenue"></td>
            <td><input type="text" v-model="item.liquidRevenue"></td>
            <td><input type="text" v-model="item.churning"></td>
            <td><input type="text" v-model="item.churningCumulative"></td>
            <td><input type="text" v-model="item.growth"></td>
            <td><input type="text" v-model="item.growthCumulative"></td>
            <td><input type="text" v-model="item.upsellDowngrade"></td>
            <td><input type="text" v-model="item.canceledRecurrency"></td>
            <td><input type="text" v-model="item.canceledRecurrencyCumulative"></td>
            <td><input type="text" v-model="item.activatedRecurrency"></td>
            <td><input type="text" v-model="item.activatedRecurrencyCumulative"></td>
            <td><input type="text" v-model="item.rescuedRecurrency"></td>
            <td><input type="text" v-model="item.rescuedRecurrencyCumulative"></td>
            <td><input type="text" v-model="item.latePayersRecurrency"></td>
            <td v-if="item.id !== undefined">
              <button class="btn blue" @click="updateMetrics(item)">Salvar</button>
            </td>
            <td v-else>
              <button class="btn blue" @click="createMetrics(item)">Criar</button>
            </td>
          </tr>
          </tbody>
        </table>
        <table id="header-fixed"></table>
      </div>
    </div>
  </ui-modal>
</template>

<script>

import moment from "moment";

export default {
  name: "metrics-modal",
  props: {
    metrics: {
      default: {}
    }
  },
  methods: {
    async updateMetrics(item) {
      const response = await this.request().put('atualizando métrica', 'dashboard/admin/metrics/' + item.id, item)
      if (response.status !== 200) {
        alert('erro')
      }
    },
    async createMetrics(item) {
      const response = await this.request().post('adicionando nova métrica', 'dashboard/admin/metrics/', item)
      if (response.status !== 200) {
        alert('erro')
      } else {
        item.id = (response.data.id);
        this.metrics[0] = item;
        this.metrics.unshift({"created_at": moment().format('YYYY-MM-DD')});
      }
    },
  }
}
</script>

<style scoped>
input {
  border: 0;
}

table tr:first-child {
  background-color: transparent;
}

table tr:nth-child(odd), tr:nth-child(odd) input {
  background-color: #eee;
  line-height: 35px;
}

table tr:nth-child(even), tr:nth-child(even) input {
  background-color: #fff;
  line-height: 35px;
}

.table > tbody > tr > td {
  padding: 0;
}

.table > tbody > tr > td:first-child {
  padding-right: 15px;
  padding-left: 5px;
}

.table > thead > th:first-child, .table > thead > tr:first-child {
  position: sticky;
  top: 0;
  z-index: 1;
}

.btn {
  width: 80px;
  height: 40px;
}

label {
  line-height: 35px;
  margin-bottom: 0;
}

</style>


<template>
    <ui-loading :is-loading="ui.isLoading">

        <div class="row">

            <form action="" @submit.prevent="loadHistories">
                <div class="col-md-2">
                    <ui-select :options="ui.users" label="Usuário" v-model="search.user_id"></ui-select>
                </div>
                <div class="col-md-2">
                    <ui-select :options="ui.options.types" label="Tipo de ação" v-model="search.type"></ui-select>
                </div>
                <div class="col-md-2">
                    <ui-select :options="fields" v-model="search.field" label="Campo Alt."></ui-select>
                </div>
                <div class="col-md-2">
                    <ui-date v-model="search.created_at_gt" label="De"></ui-date>
                </div>
                <div class="col-md-2">
                    <ui-date v-model="search.created_at_lt" label="Até"></ui-date>
                </div>

                <div class="col-md-1">
                    <button type="submit" class="btn btn-info" style="margin-top: 25px">Pesquisar</button>
                </div>
            </form>

            <div class="col-md-12">
                <table ref="list" class="table table-striped">
                    <thead>
                    <tr>
                        <th>Data da alteração</th>
                        <th>Alterado Por</th>
                        <th>Ação</th>
                        <th>Alteração</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(history, key) in histories" :key=key>
                        <td>{{history.created_at | datetime}}</td>
                        <td>{{history.user.name}}</td>
                        <td>
                            <p>{{history.typeInfo.name}}</p>
                        </td>
                        <td>
                            <ul v-if="countKeys(history) > 0" class="list-group" style="list-style: none">
                                <li class="" v-for="(item, index) in filtered(history.delta)" :key="index">Antigo valor de 
                                    {{index |
                                    trans('clients')}}
                                    era {{ format(index,history.delta) }}
                                </li>
                            </ul>

                            <p v-else>
                                {{history.message}}
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>

    </ui-loading>
</template>

<script>

    import _ from 'lodash'
    import translate from '@lib/trans'
    import formats from './formats.ts';

    export default {
        name: "History",

        props: {
            client: {
                required: true
            },
            options: {
                required: true
            }
        },

        computed: {
            fields() {
                let fields = []
                _.forEach(formats, (val, index) => {
                    fields.push({'id': index, 'name': _.capitalize(translate(`clients.${index}`))})
                })
                return fields
            }
        },

        data() {
            return {
                histories: [],
                search: {
                    created_at_gt: null,
                    created_at_lt: null,
                    type: null,
                    user_id: null,
                    field: null

                },
                ui: {
                    options: {},
                    users: [],
                    isLoading: true
                }
            }
        },

        mounted() {
            this.loadOptions().then(() => {
                this.loadHistories();
            });
        },

        methods: {
            countKeys(value) {
                return _.keys(value.delta).length
            },

            filtered(delta) {
                if (! this.search.field) {
                    return delta
                }


                return { [this.search.field]: delta[this.search.field] }

            },

            format(index, value) {

                if (typeof value === 'string' && value.length === 0) {
                    return 'Vazio'
                }

                return formats[index](value, this.options)
            },


            loadOptions() {
                return this.request().get('Carregando opções', `options/client-history`)
                    .then(response => {
                        this.ui.options = response.data
                    })
                    .then(this.loadUsers)
                    .then(() => this.ui.isLoading = false)
            },

            loadUsers() {
                return this.request().get('Carregando usuários envolvidos', `clients/${this.client.id}/histories/users`)
                    .then(response => {
                        this.ui.users = response.data
                    })
            },
            loadHistories() {

                let reduced = _.reduce(this.search, (carry, value, index) => {
                    if (value == null || value.length === 0) {
                        return carry
                    }

                    carry[index] = value
                    return carry
                }, {})


                let filters = {
                    order: 'id',
                    direction: 'desc',
                }
                if (_.keys(reduced).length) {

                    if (_.hasIn(reduced, 'created_at_lt')) {
                        reduced['created_at_lt'] = reduced['created_at_lt'].replace('00:00:00', '23:59:59')
                    }

                    filters['query'] = reduced
                }


                return this.request().block(this.$refs.list).get('Carregando Historico', `clients/${this.client.id}/histories`, filters)
                    .then(response => {
                        this.histories = response.data
                    });
            },
        }
    }
</script>

<style scoped>

</style>
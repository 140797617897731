<template>
  <div class="row">
    <div class="col-md-6">
      <div class="col-md-12">
        <ui-select
          label="Periodicidade"
          :options="ui.periodicity"
          placeholder="Periodicidade"
          name="status"
          v-model="current.periodicity"
        ></ui-select>
        <ui-money class="plan_value" :readonly="current.value > 0" label="Valor total" v-model="current.value"/>
        <button v-if="current.value > 0" @click="sendNote" class="btn blue">Editar recorrência</button>
        <ui-modal v-if="current.change_recurrency" width="30%" @close="current.change_recurrency = false" ref="recurrencyModal">
          <div slot="header">
            <h4>Alteração do valor de recorrência do cliente</h4>
          </div>
          <div slot="body">
            <form ref="noteForm" action @submit.prevent="">
              <ui-money name="value" v-model="current.new_recurrency" label="Novo valor da recorrência"></ui-money>
              <ui-confirm
                  title="Você deseja confirmar a alteração na recorrência?"
                  v-if="modalConfirm === true"
                  @confirm="updateRecurrency"
                  @close="modalConfirm = false">
              </ui-confirm>
              <ui-select
                  :options="ui.reason"
                  keyName="id"
                  label="Motivo"
                  :required="true"
                  name="changing_reason"
                  v-model.number="current.changing_reason"
              ></ui-select>
              <ui-date v-model="current.validity_date" label="Data de vigência"></ui-date>
            </form>
          </div>
          <div slot="footer">
            <div class="pull-right">
              <button class="btn btn-default" @click="current.change_recurrency = false" type="submit">Cancelar</button>
              <button class="btn blue" :disabled="checkFields" @click="modalConfirm = true">Salvar</button>
            </div>
          </div>
        </ui-modal>

        <ui-text type="number" label="Número de parcelas"></ui-text>
      </div>
    </div>
    <div class="col-md-6">
      <div class="col-md-12">
        <label for>
          Recorrencia:
          <span v-if="this.current.periodicity > 0">{{this.recurrency | money}}</span>
          <span v-else>Não é uma recorrência</span>
        </label>
      </div>
      <div class="col-md-12">
        <label for>
          ID:
          <span>{{this.current.id}}</span>
        </label>
      </div>
    </div>
    <div class="col-md-12">
      <div class="pull-right">
        <div class="col-md-6">
          <button class="btn btn-info" @click="update">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const _ = require("lodash");
export default {
  name: "PlanDetails",
  props: {
    clientPlan: {
      default: {}
    }
  },

  data() {
    let current = _.cloneDeep(this.clientPlan);
    current.new_recurrency = null
    current.change_recurrency = false
    current.client_plan_id = current.id
    current.validity_date = null
    current.changing_reason = null;
    return {
      current,
      modalConfirm: false,
      ui: {
        reason: [
          {
            id: 0,
            name: "Upgrade/Downgrade",
          },
          {
            id: 1,
            name: "Correção de valor",
          }
        ],
        periodicity: [
          {
            id: 0,
            name: "Não é uma recorrência"
          },
          {
            id: 1,
            name: "mensal (1 mês)"
          },
          {
            id: 2,
            name: "bimestral (2 meses)"
          },
          {
            id: 3,
            name: "Trimestral (3 meses)"
          },
          {
            id: 6,
            name: "Semestral (6 meses)"
          },
          {
            id: 12,
            name: "Anual (12 meses)"
          }
        ]
      }
    };
  },
  watch: {
    clientPlan: function(newValue) {
      this.current = _.clone(newValue);
    }
  },
  computed: {
    recurrency() {
      if (this.current.periodicity === 0) {
        return 0;
      }
      return this.current.value / this.current.periodicity;
    },
    checkFields() {
      let new_recurrency = this.current.new_recurrency == null || this.current.new_recurrency === ''
      let current_reason = this.current.changing_reason == null || this.current.changing_reason === ''
      return new_recurrency || current_reason;
    }
  },

  methods: {
    update() {
      this.request().put(
        "Atualizando plano",
        `/client-plans/${this.current.id}`,
        {
          ...this.current
        }
      );
    },
    updateRecurrency() {
      this.request().put(
          "Alterando recorrência",
          `/client-plans/${this.current.id}/recurrency`,
          {
            ...this.current,
            plan_value: this.current.new_recurrency,
            value: this.current.new_recurrency,
            recurrency_value: this.current.new_recurrency - this.current.value,
          }
      );
      this.current.value = this.current.new_recurrency;
      this.modalConfirm = false;
      setTimeout(() => this.current.change_recurrency = false, 800)
    },
    sendNote() {
        this.$emit('sentnote')
        this.current.change_recurrency = true
    }
  }
};
</script>
<style scoped>
.plan_value {
  display: inline-block;
  width: 70%
}
</style>

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const toastr = require('toastr');
exports.default = {
    showToastr(type, message, title = '') {
        switch (type) {
            case 'error':
                toastr.error(message, title);
                return;
            case 'success':
                toastr.success(message, title);
                return;
            default:
                toastr.info(message, title);
        }
    }
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const toaster = require('../lib/toastr');
exports.default = {
    methods: {
        showToastr(type, message, title = '') {
            toaster.showToastr(type, message, title);
        }
    }
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [
    {
        id: 'equals',
        name: 'Igual',
    },
    {
        id: 'higher',
        name: 'Maior Que'
    },
    {
        id: 'lower',
        name: 'Menor que'
    },
    {
        id: 'between',
        name: 'Entre',
        secondary: true
    },
    {
        id: 'exists',
        name: 'Um Valor deve'
    },
    {
        id: 'date',
        name: 'A data'
    }
];

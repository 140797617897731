<template>
  <ui-loading :is-loading="ui.loading">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="title">
            <h3>Dados do mês</h3>
            <h4>Vendedor: <b>{{ this.USER.name }}</b></h4>
          </div>
          <div class="col-md-3">
                  <span>Ativados:
                      <b>
                      <router-link
                          :to="{name: 'clients.index', query: {status_classification: 1, vendor_id: this.USER.id}}">
                              {{ current.activated.qtd }}
                      </router-link>
                      </b>
               <small :class="{
              'text-danger': previous.activated.qtd > current.activated.qtd,
              'text-success': previous.activated.qtd < current.activated.qtd,
              'text-info': previous.activated.qtd === current.activated.qtd,
            }" :title="'mês passado: '+ previous.activated.qtd"><i :class="{
               'fa fa-arrow-down': previous.activated.qtd > current.activated.qtd,
               'fa fa-arrow-up': previous.activated.qtd < current.activated.qtd,
               'fa fa-arrow-right': previous.activated.qtd === current.activated.qtd,
            }"></i> {{ previous.activated.qtd }}</small><small
                        class="small_mmr"> MMR: {{ 0 + current.activated.mmr | money }}</small>
      </span>
          </div>
          <div class="col-md-3">
                  <span>Cancelados:
                      <b>
                      <router-link
                          :to="{name: 'clients.index', query: {status_classification: 3, vendor_id: this.USER.id}}">
                              {{ current.canceled.qtd }}
                      </router-link>
                      </b>
                                   <small :class="{
              'text-danger': previous.canceled.qtd > current.canceled.qtd,
              'text-success': previous.canceled.qtd < current.canceled.qtd,
              'text-info': previous.canceled.qtd === current.canceled.qtd,
            }" :title="'mês passado: '+ previous.canceled.qtd"><i :class="{
               'fa fa-arrow-down': previous.canceled.qtd > current.canceled.qtd,
               'fa fa-arrow-up': previous.canceled.qtd < current.canceled.qtd,
               'fa fa-arrow-right': previous.canceled.qtd === current.canceled.qtd,
            }"></i> {{ previous.canceled.qtd }}</small><small
                        class="small_mmr"> MMR: {{ 0 + current.canceled.mmr | money }}</small>
      </span>
          </div>
          <div class="col-md-3">
                  <span>Upsell/Downgrade:
                      <b>
                      <router-link
                          :to="{name: 'clients.index',
                          query: {ids: current.upsell.ids, vendor_id: this.USER.id}}">
                              {{ current.upsell.qtd }}
                      </router-link>
                      </b>
                                   <small :class="{
              'text-danger': previous.upsell.qtd > current.upsell.qtd,
              'text-success': previous.upsell.qtd < current.upsell.qtd,
              'text-info': previous.upsell.qtd === current.upsell.qtd,
            }" :title="'mês passado: '+ previous.upsell.qtd"><i :class="{
               'fa fa-arrow-down': previous.upsell.qtd > current.upsell.qtd,
               'fa fa-arrow-up': previous.upsell.qtd < current.upsell.qtd,
               'fa fa-arrow-right': previous.upsell.qtd === current.upsell.qtd,
            }"></i> {{ previous.upsell.qtd }}</small><small
                        class="small_mmr"> MMR: {{ 0 + current.upsell.mmr | money }}</small>
      </span>
          </div>
        </div>
        <hr>
        <div style="margin-top: 20px" class="row">
          <div class="col-md-6" style="padding: 0 10px 0 0;">
            <div class="title">
              <h3 class="inline-block">Meus clientes</h3>
              <input class="form-control search-clients-bar" type="text" v-model="search"
                     :placeholder=searchClientsPlaceholderText>
            </div>
            <div v-if="clients.length > 0" class="table-area">
              <div class="table-container">
                <div class="table-row heading">
                  <div style="min-width: 80px" class="hidden-xs row-item">Código</div>
                  <div class="row-item">Nome</div>
                </div>
                <div v-for="item in filteredClients" class="table-row">
                  <div style="min-width: 80px" class="hidden-xs row-item"><b>{{ item.code }}</b></div>
                  <div class="row-item"><a href="#" @click.prevent="openClient(item)">{{ item.name }}</a>
                    <small v-if="item.status === 4" style="margin-left: 3px;" class="alert-danger">pré-cliente</small>
                    <small v-if="item.status === 2" style="margin-left: 3px;" class="alert-warning">suspenso</small></div>
                </div>
                <ui-meeting-modal :vendorName="USER.name" :webSiteDomain="webSiteDomain"
                                  :sciRelatedData="sciRelatedData" :client="current.client" :hasApp="appEnabled"
                                  v-if="showClient"
                                  @close="showClient = false">
                </ui-meeting-modal>
              </div>
            </div>
            <div v-else>
              <h3>Nenhum cliente vinculado a esse vendedor.</h3>
            </div>
          </div>
          <div class="col-md-6" style="padding: 0 10px 0 0;">
            <div class="title">
              <h3>Próximas Reuniões</h3>
            </div>
            <div v-if="meetings.length > 0" class="table-area">
              <div class="table-container">
                <div class="table-row heading">
                  <div style="min-width: 140px" class="row-item">Data/Hora</div>
                  <div style="min-width: 80px" class="hidden-xs row-item">Código</div>
                  <div class="row-item">Nome</div>
                </div>
                <div v-for="item in meetings" class="table-row">
                  <div class="row-item">{{ item.due_to | datetime(format = 'DD/MM/YYYY HH:mm') }}</div>
                  <div style="min-width: 80px" class="hidden-xs row-item"><b>{{ item.client.code }}</b></div>
                  <div class="row-item client-name"><a href="#" @click.prevent="openMeeting(item)">{{ item.client.name }}</a><small v-if="item.client.status === 4" style="margin-left: 3px;" class="alert-danger">pré-cliente</small>
                    <small v-if="item.client.status === 2" style="margin-left: 3px;" class="alert-warning">suspenso</small></div>
                </div>
                <ui-meeting-modal :vendorName="USER.name" :webSiteDomain="webSiteDomain"
                                  :sciRelatedData="sciRelatedData" :meeting="true" :client="current.client"
                                  :hasApp="appEnabled"
                                  v-if="showMeeting"
                                  @close="showMeeting = false">
                </ui-meeting-modal>
              </div>
            </div>
            <div v-else>
              <h3>Nenhuma reunião agendada.</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ui-loading>
</template>

<style scoped>
.title {
  border-bottom: 1px solid #365F42;
  margin-bottom: 10px
}

.small_mmr {
  margin-left: 5px;
}

small, i {
  font-size: 12px !important;
}

.search-clients-bar {
  display: inline-block;
  width: 35%;
  position: relative;
  left: 15px;
}
.table-area {
  height: 500px;
  overflow-y: scroll;
}
.table-container {
  display: flex;
  flex-flow: column nowrap;
  background-color: white;
  width: 100%;
  margin: 0 auto;
  border-radius: 4px;
  border-bottom: 1px solid #DADADA;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .08);
}

.table-row {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  border-bottom: 1px solid #dadada;
}

.heading {
  position: sticky;
  border-bottom: 1px solid #dadada;
  top: 0;
  padding: 5px 0;
  background-color: #fff;
  color: #3e3e3e;
  font-weight: bold;
}

.row-item {
  font-size: 14px;
  padding: 8px 15px;
}

.row-item:last-child {
  border-bottom: 0;
}

@media screen and (max-width: 991px) {
  h1, h2, h3, h4, h5, h6 {
    padding: 0;
  }

  div.col-md-3, div.col-md-5, div.col-md-7 {
    padding: 0;
  }

  div.col-md-3 {
    margin: 5px 0;
  }

  .row > div.col-md-6 {
    margin-bottom: 20px;
  }

  input.search-clients-bar {
    width: 45%;
  }

  .row-item.client-name {
    max-width: 150px;
  }
}

</style>

<script>
import {mapGetters} from "vuex";
import moment from "moment";

export default {
  name: "Vendor",
  data() {
    return {
      search: "",
      clients: [],
      clients_plan_id: [],
      meetings: [],
      meeting: {},
      showClient: false,
      showMeeting: false,
      sciRelatedData: {},
      webSiteDomain: "",
      appEnabled: false,
      ui: {
        loading: true,
      },
      current: {
        client: {},
        activated: {
          qtd: 0,
          mmr: 0,
        },
        canceled: {
          qtd: 0,
          mmr: 0,
        },
        upsell: {
          ids: [],
          qtd: 0,
          mmr: 0,
        }
      },
      previous: {
        activated: {
          qtd: 0,
          mmr: 0,
        },
        canceled: {
          qtd: 0,
          mmr: 0,
        },
        upsell: {
          qtd: 0,
        }
      },
    }
  },
  computed: {
    ...mapGetters(['USER']),
    filteredClients() {
      return this.clients.filter(client => {
        return (client.name.toLowerCase().match(this.search.toLowerCase()) || client.code.match(this.search.toLowerCase()))
      })
    },
    searchClientsPlaceholderText() {
        return window.matchMedia('(max-width: 991px)').matches ? 'Código/Nome' : 'Digite um código ou nome.';
    }
  },
  mounted() {
    this.getClients()
    this.getNotes()
  },
  methods: {
    async getClients() {
      const response = await this.request().get("Carregando clientes", "/clients/dashboard", {
        query: {
          vendor_id: this.USER.id,
          status: [1, 2, 4]
        },
        limit: 300,
      })
      response.data.forEach((clients) => {
        this.clients.push(clients)
        clients.plans_ids.forEach((id) => {
          this.clients_plan_id.push(id)
        })
        if (clients.activated_at) {
          switch (moment(clients.activated_at).format('YYYY-MM')) {
            case moment().format('YYYY-MM'):
              this.current.activated.qtd++
              this.current.activated.mmr += clients.plan_recurrency
              break;
            case moment().subtract(1, 'months').format('YYYY-MM'):
              this.previous.activated.qtd++
              break;
          }
        }
        if (clients.cancel_date) {
          switch (moment(clients.cancel_date.date).format('YYYY-MM')) {
            case moment().format('YYYY-MM'):
              this.current.canceled.qtd++
              this.current.mmr += clients.plan_recurrency
              break;
            case moment().subtract(1, 'months').format('YYYY-MM'):
              this.previous.canceled.qtd++
              break;
          }
        }
      });
      await this.getUpsell()
    },
    async getNotes() {
      const response = await this.request().get("Carregando próximas reuniões", `dashboard/vendor/meetings/${this.USER.id}`)
      this.meetings = response.data
    },
    async getUpsell() {
      const suffix = this.clients_plan_id.length > 0 ? '/' : '';
      const response = await this.request().get("Carregando upsell", `/upsells${suffix}` + this.clients_plan_id.join(','))
      response.data.forEach((upsell) => {
        switch (moment(upsell.validity_date).format('YYYY-MM')) {
          case moment().format('YYYY-MM'):
            this.current.upsell.qtd++
            this.current.upsell.ids.push(upsell.plan.client_id)
            this.current.upsell.mmr += upsell.recurrency_value
            break;
          case moment().subtract(1, 'months').format('YYYY-MM'):
            this.previous.upsell.qtd++
            break;
        }
      })
      this.current.upsell.ids = this.current.upsell.ids.join('&')
      this.ui.loading = false
    },
    async getClientById(meeting = false) {
      const response = await this.request().get(`Carregando dados de cliente`,
          `/clients/${this.current.client.id}/summary`)
      this.current.client = response.data
      this.sciRelatedData = response.data.sci
      this.webSiteDomain = response.data.domain !== null ? response.data.domain : response.data.imobDomain
      this.checkIfHasApp(response)
      if (meeting !== false) {
        this.current.client = {
          ...this.current.client,
          ...meeting
        }
        return this.showMeeting = !this.showMeeting
      }
      return this.showClient = !this.showClient
    },
    async openMeeting(meeting) {
      this.current.client = {
        ...meeting,
        name: meeting.client.name,
        id: meeting.client.id,
      }
      await this.getClientById(meeting)
    },
    async openClient(item) {
      this.current.client = item
      await this.getClientById()
    },
    checkIfHasApp(client) {
      this.appEnabled = false
      client.data.plans.forEach(plan => {
        plan.clientProducts.forEach(clientProduct => {
          clientProduct.configs.forEach(config => {
            if (config.name === "app_enabled" && config.value === "1") {
              this.appEnabled = true
            }
          })
        })
      })
    }
  }
}
</script>
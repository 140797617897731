<template>
    <div class="form-group">
        <label
                :for="id"
                v-if="label !== false"
                class="control-label"
        >
            {{label}}
        </label>
        <i class="glyphicon glyphicon-asterisk required-icon" v-if="required"></i>
        <div
                :class="{
            'input-group': inputGroup || icon
            }">
                <span v-if="icon" class="input-group-addon">
                        <i :class="iconClass"></i>
                    </span>
            <input
                    class="form-control"
                    ref="element"
                    autocomplete="off"
                    :id="id"
                    :name="name"
                    :type="type"
                    :value="value"
                    :placeholder="placeholder"
                    :required="required"
                    :disabled="disabled"
                    :readonly="readonly"
                    @keyup.enter="$emit('submit')"
                    @keyup="updateValue($event.target.value)"
            >
            <slot></slot>
        </div>
        <small class="form-text text-muted" v-if="hint">{{hint}}</small>

    </div>
</template>

<script>
    const uniq = require('uniqid')
    const maskcore = require('text-mask-core')

    const masks = {
        phone: (val) => {
            if (val.replace(/_/g, '').trim().length === 15) {
                return ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /[0-9]?/]
            }

            return ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /[0-9]?/]
        },

        cpf_cnpj: (val) => {
            if (val.replace(/_/g, '').trim().length <= 14) {
                return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/,/\d/,  '-', /\d/, /\d/]
            }

            return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/,/\d/,  '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
        },

        cep: (val) => {
            return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '-' ,  /\d/, /\d/,/\d/]
        },
        rg: (val) => {
            return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.' ,  /\d/, /\d/,/\d/, '-', /\d/,/\d/]
        }
    }

    export default {
        name: "text-input",

        props: {
            type: {
                default: 'text'
            },
            name: {
                default: ''
            },
            value: {
                default: ""
            },
            required: {
                type: Boolean,
                default: false
            },
            hint: {
                type: String,
                default: ""
            },
            readonly: {
                default: false
            },
            id: {
                default: () => uniq()
            },
            label: {
                default: ""
            },
            disabled: {
                default: false
            },
            icon: {
                default: false
            },
            inputGroup: {
                default: false
            },
            mask: {
                default: false
            },
            placeholder: {
                default: ''
            },
        },

        data() {
            return {
                textMask: null
            }
        },

        computed: {
            iconClass() {
                if (this.icon === false) {
                    return {}
                }

                let val = {}
                val[this.icon] = true
                return val
            }
        },

        mounted() {

            if (this.mask) {
                this.textMask = maskcore.createTextMaskInputElement({
                    inputElement: this.$refs.element,
                    mask: masks[this.mask](''),
                    guide: true,
                    placeholderChar: ' ',
                    showMask: true
                })
            }
        },

        methods: {
            updateValue(val) {
                if (this.textMask !== null) {
                    this.textMask.update(val, {inputElement: this.$refs.element, mask: masks[this.mask](val)})
                    this.$emit('input', this.$refs.element.value)
                    return;
                }

                this.$emit('input', val)
            }
        }


    }
</script>

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScoreRanges = [
    {
        id: "80-100",
        level: "success",
        icon: "fa-smile-o",
        min: 80,
        max: 101
    },
    {
        id: "50-80",
        level: "info",
        icon: "fa-meh-o",
        min: 50,
        max: 80
    },
    {
        id: "30-50",
        level: "warning",
        icon: "fa-frown-o",
        min: 30,
        max: 50
    },
    {
        id: "0-30",
        level: "danger",
        icon: "fa-frown-o",
        min: 0,
        max: 30
    }
];

<template>
  <div class="row" ref="form">
    <div class="col-md-12" style="min-height: 140px">
      <div class="col-md-3">
        <p>
          Status:
          <strong>{{statusText}}</strong>
        </p>
        <p>
          Instalado:
          <strong>
            {{installStatus}}
            <i :class="installClass"></i>
          </strong>
        </p>
        <p>
          Data Ins:
          <strong
            v-if="isInstalled && getConfig('installed').updated_at "
          >{{getConfig('installed').updated_at | date}}</strong>
          <strong v-else>-</strong>
        </p>
      </div>
      <div class="col-md-3">
        <p>
          Limite de e-mails:
          <strong>{{clientProduct.managerConfigs['total_emails']}}</strong>
        </p>
        <p>
          Limite de usuários:
          <strong>{{clientProduct.managerConfigs['total_users']}}</strong>
        </p>
        <p>
          Usuários em uso:
          <strong>{{clientProduct.managerConfigs['current_users']}}</strong>
        </p>
        <p>
          Dias Sem logar:
          <strong>{{clientProduct.managerConfigs['days_without_login']}}</strong>
        </p>
      </div>
      <div class="col-md-3">
        <p>
          Limite de imóveis:
          <strong>{{clientProduct.managerConfigs['total_properties']}}</strong>
        </p>
        <p>
          Imóveis em uso:
          <strong>{{clientProduct.managerConfigs['current_properties']}}</strong>
        </p>
        <p>
          Dias sem modificar imóveis:
          <strong>{{clientProduct.managerConfigs['days_until_last_property_change']}}</strong>
        </p>
      </div>
      <div class="col-md-3">
        <p>
          Score Login:
          <strong>
            {{clientProduct.managerConfigs['percent_login_score']}}
            <client-score :score="clientProduct.managerConfigs['percent_login_score']" />
          </strong>
        </p>
        <p>
          Score Imóveis:
          <strong>
            {{clientProduct.managerConfigs['percent_property_score']}}
            <client-score :score="clientProduct.managerConfigs['percent_property_score']" />
          </strong>
        </p>

        <p>
          Score Negócios:
          <strong>
            {{clientProduct.managerConfigs['percent_deals_score']}}
            <client-score :score="clientProduct.managerConfigs['percent_deals_score']"></client-score>
          </strong>
        </p>

        <p>
          Score Portais:
          <strong>{{clientProduct.managerConfigs['percent_portal_score']}}</strong>
          <client-score :score="clientProduct.managerConfigs['percent_portal_score']" />
        </p>

        <p>
          Total:
          <strong>
            {{clientProduct.managerConfigs['score']}}
            <client-score :score="clientProduct.managerConfigs['score']" />
          </strong>
        </p>
      </div>
    </div>
    <div class="col-md-12">
      <div class="pull-right">
        <div class="col-md-12">
          <div class="form-actions">
            <button class="btn" @click.prevent='toggleApp' :class="{blue: !this.appEnabled, red : this.appEnabled}">
              <i class="fa fa-phone"></i>
              {{ appMessage }}
            </button>
            <button @click="install" class="btn blue" type="button" v-show="!isInstalled">
              <i class="fa fa-cogs"></i>
              Instalar
            </button>
            <a target="_blank" :href="clientProduct.log_url" class="btn blue">
              <i class="fa fa-download"></i>
              Baixar Log
            </a>
            <button
              @click="changeActiveStatus($refs.form)"
              type="button"
              class="btn"
              :class="statusButtonClass"
            >
              <i :class="statusButtonIcon"></i>
              {{statusButtonText}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../Details/mixin";
import clientScore from "@components/client/Score.vue";
export default {
  name: "sci-online",

  mixins: [mixin],
  data() {
    const app_enabled_config = this.getConfig('app_enabled', {value: 0});
    return {
      appEnabled: !!parseInt(app_enabled_config.value)
    }
  },
  components: {
    clientScore
  },

  computed: {
    appMessage() {
      if (this.appEnabled) {
        return 'Desativar app'
      }
      return 'Ativar app'

    }
  },
  methods: {
    toggleApp() {
      this.request().post('Trocando Acesso ao APP', `client-products/${this.clientProduct.id}/sci-online/toggle-app`).then(() => {
        this.$emit('updated', this.current)
        this.appEnabled = ! this.appEnabled;
      })
    }
  }

};
</script>

<style scoped>
</style>
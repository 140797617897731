"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const vue_property_decorator_1 = require("vue-property-decorator");
let FileElement = class FileElement extends vue_property_decorator_1.Vue {
    get icon() {
        switch (true) {
            case this.file.mime === 'application/pdf':
                return { 'fa-file-pdf-o': true };
            case this.file.mime.indexOf('image') >= 0:
                return { 'fa-file-image-o': true };
            default:
                return { 'fa-file-o': true };
        }
    }
    download() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.request().get("Baixando Arquivo", 'download', { path: this.file.url }, { isBlob: true });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(response.data);
            link.download = this.file.label;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }
};
__decorate([
    vue_property_decorator_1.Prop()
], FileElement.prototype, "file", void 0);
__decorate([
    vue_property_decorator_1.Prop({ default: true })
], FileElement.prototype, "canDelete", void 0);
FileElement = __decorate([
    vue_property_decorator_1.Component
], FileElement);
exports.default = FileElement;

"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const vue_property_decorator_1 = require("vue-property-decorator");
const scoreRanges_1 = require("./scoreRanges");
let ClientScore = class ClientScore extends vue_property_decorator_1.Vue {
    get labelClass() {
        const range = this.scoreRange;
        if (range !== null) {
            return `label-${range.level}`;
        }
    }
    get iconClass() {
        const range = this.scoreRange;
        if (range !== null) {
            const tmp = {};
            tmp[range.icon] = true;
            return tmp;
        }
    }
    get scoreRange() {
        const score = scoreRanges_1.ScoreRanges.filter(i => {
            return this.score >= i.min && this.score < i.max;
        });
        return score.length ? score[0] : null;
    }
};
__decorate([
    vue_property_decorator_1.Prop()
], ClientScore.prototype, "score", void 0);
__decorate([
    vue_property_decorator_1.Prop()
], ClientScore.prototype, "title", void 0);
ClientScore = __decorate([
    vue_property_decorator_1.Component
], ClientScore);
exports.default = ClientScore;

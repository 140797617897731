<template>
  <ui-modal :width=getModalWidth :margin=getModalMargin @close="$emit('close')">
    <div slot="header">
      <h3 v-if="this.meeting">Informações da reunião: <b>{{ client.name }}</b></h3>
      <h3 v-else>Informações do cliente: <b>{{ client.name }}</b></h3>
    </div>
    <div class="body" slot="body">
      <div v-if="this.meeting" class="meetingData">
        <span><b>Data da reunião:</b> {{ client.due_to | datetime(format = 'DD/MM/YYYY HH:mm') }}</span><br>
        <span class="meeting-span"><b>Motivo Visita/Reunião:</b> {{ client.description }}</span><br>
      </div>
      <span><b>Responsável:</b> {{ vendorName }}</span><br>
      <span><b>Usuário:</b> {{ client.name }}</span><br>
      <span><b>Código:</b> {{ client.client_code }}</span><br>
      <span v-if="webSiteDomain !== null"><b>Site:</b><a class="websiteDomain" target="_blank" :href="webSiteDomain"> {{ webSiteDomain }}</a></span>
      <span v-else><b>Site:</b> Não encontrado!</span>
      <br>
      <div v-if="recurrencies.length > 0">
      <div v-for="(recurrency, key) in recurrencies">
        <span><b>Recorrência:</b> {{ recurrency | money }} ({{ planName[key] }})</span><br>
      </div>
      </div>
      <div v-else>
        <span><b>Nenhum plano encontrado! </b></span><br>
      </div>
      <span><b>Quantidade de Usuários:</b> {{ sciRelatedData ? `${sciRelatedData.current_users} / ${sciRelatedData.total_users}` : 0 }}</span><br>
      <span><b>Quantidade de Imóveis:</b> {{ sciRelatedData ? `${sciRelatedData.current_properties} / ${sciRelatedData.total_properties}` : 0 }}</span><br>
      <span><b>Tem o app?:</b> {{ hasApp === true ? "sim" : "não tem" }}</span><br>
      <span><b>Cadastrado em:</b> {{ client.created_at | datetime(format = 'DD/MM/YYYY') }}</span><br>
      <span v-if="sciRelatedData && sciRelatedData.portals_in_use"><b>Portais:</b> {{ sciRelatedData.portals_in_use }}</span>
      <span v-else><b>Nenhum portal cadastrado!</b></span><br>
      <router-link :to="{name: 'clients.edit.index', params: {id: client.client_id}}">
        <button type="button" class="btn btn-primary">
        Mais detalhes
        </button>
      </router-link>
    </div>
  </ui-modal>
</template>
<style scoped>
span {
  display: inline-block;
  margin-bottom: 10px !important;
}
.meeting-span {
  white-space: pre-wrap;
}
.websiteDomain {
  word-break: break-all;
}
</style>
<script>

export default {
  name: "vendor-meeting-modal",
  data() {
    return {
      width: '40%',
      margin: '',
      recurrencies: [],
      planName: [],
    }
  },
  filters: {
    money: (value) => {
      if(isNaN(value)) {
        return "Valor não definido";
      }
            const format = new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 2,
            });
            return format.format(value)
    }
  },
  computed: {
  getModalWidth() {
    return window.matchMedia('(max-width: 991px)').matches ? '80%' : this.width;
  },
  getModalMargin() {
    return window.matchMedia('(max-width: 991px)').matches ? '5px auto' : this.margin;
  }
  },
  mounted() {
    this.getPlanRecurrency()
  },
  props: {
    meeting: {
      type: Boolean,
    },
    client: {
      type: Object,
      default: () => {
        return {
          plans: []
        }
      }
    },
    vendorName: "",
    sciRelatedData: {
      type: Object,
      default: () => {
        return {
          current_users: 0,
          current_properties: 0,
          portals_in_use: ""
        }
      }
    },
    webSiteDomain: "",
    hasApp: false,
  },
  methods: {
    getPlanRecurrency() {
        this.client.plans.forEach(plan => {
          this.recurrencies.push(plan.value / plan.periodicity)
          this.planName.push(plan.plan.description)
        });
    }
  }
}
</script>